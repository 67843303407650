import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {
    onDevice: boolean;
    toast: any;
    constructor(private platform: Platform, public toastCtrl: ToastController) {
        this.onDevice = this.platform.is('cordova');
    }

    showToast(message, duration, position, showclosebtn, btntext, color = '') {
        this.toastCtrl.dismiss().then((obj) => {
        }).catch(() => {
        }).then(() => {
            this.toast = this.toastCtrl.create({
                animated: true,
                mode: 'ios',
                message: message,
                duration: duration,
                position: position,
                color: color,
            }).then((obj) => {
                obj.present();
            });
        });
    }

    showToastWithoutAction(message) {
        this.toast = this.toastCtrl.create({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'danger'
        }).then((toastData) => {
            console.log('in toast');
            console.log(toastData);
            toastData.present();
        });
    }

    destroyToast() {
        this.toast = this.toastCtrl.dismiss();
    }

    /* async showToast(message, duration?, position?, showclosebtn?, btntext?, color = '') {
        await Toast.show({
            text: message,
            duration: duration,
            position: position
          });
    } */
}