import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { HTTP } from '@ionic-native/http/ngx';
import { ClipboardModule } from 'ngx-clipboard';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HttpBackend } from '@angular/common/http';
import { UtilsModule } from '../utils/utils.module';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { AppCenterCrashes } from '@ionic-native/app-center-crashes/ngx';

// geolocation and native-geocoder
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { UserPopoverPagePage } from './pages/user-popover-page/user-popover-page.page';
import { AuthService } from './services/auth.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { TitleBarComponentModule } from './component/title-bar/title-bar.component.module';
import { TemperatureHelperComponentModule } from './component/temperature-helper/temperature-helper.component.module';
import { InfoPopoverComponentModule } from './component/info-popover/info-popover.component.module';

export function createTranslateLoader(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend), "./assets/i18n/", ".json");
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('ja');
    if (localStorage.getItem('setLanguage')) {
      return translate.use(localStorage.getItem('setLanguage')).toPromise();
    }
    else {
      return translate.use('ja').toPromise();
    }
  };
}


@NgModule({
  declarations: [AppComponent, UserPopoverPagePage],
  entryComponents: [],
  imports: [
    BrowserModule,
    UtilsModule,
    IonicModule.forRoot({
      animated: false
    }),
    AppRoutingModule,
    HttpClientModule,
    ClipboardModule,
    TranslateModule.forChild(),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        // deps: [HttpClient]
        deps: [HttpBackend]
      },
    }),
    TitleBarComponentModule,
    TemperatureHelperComponentModule,
    InfoPopoverComponentModule
  ],
  providers: [HTTP,
    InAppBrowser,
    Geolocation,
    NativeGeocoder,
    ScreenOrientation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    File,
    FileOpener,
    BarcodeScanner,
    Diagnostic,
    AndroidPermissions,
    SplashScreen,
    AppCenterCrashes,
    FirebaseMessaging,
    Device,
    FirebaseAnalytics,
    StatusBar,
    AuthService,
    Deeplinks
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
