import {
  SecureStorage,
  SecureStorageObject,
} from "@ionic-native/secure-storage/ngx";
import { Keychain } from "@ionic-native/keychain/ngx";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: "root",
})
export class KeychainService {
  currentPlatform: string;
  //storageObj: SecureStorageObject;
  storageObj: Storage;
  constructor(
    private secureStorage: SecureStorage,
    private keyChain: Keychain,
    private platform: Platform,
    private storage: Storage
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is("android") && !this.platform.is("mobileweb")) {
        this.currentPlatform = "android";
        this.storage
          .create()
          .then((storage: Storage) => {
            this.storageObj = storage;
            console.log("storage obj", this.storageObj);
            //  Promise.resolve("success");
          })
          .catch((err) => {
            console.log("error in keychain", err);
            //     Promise.resolve("error");
          });
      } else if (this.platform.is("ios") && !this.platform.is("mobileweb")) {
        this.currentPlatform = "ios";
      } else if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
        this.currentPlatform = "browser";
      }
    });
  }

  setKeychain(key, value, callDelay?: any) {
    return new Promise((resolve, reject) => {
      if (callDelay) {
        setTimeout(() => {
          this.keyChain
            .set(key, value, false)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        }, 1500);
      } else {
        this.keyChain
          .set(key, value, false)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  getKeychain(key, callDelay?: any) {
    return new Promise((resolve, reject) => {
      if (callDelay) {
        setTimeout(() => {
          this.keyChain
            .get(key, "")
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        }, 1500);
      } else {
        this.keyChain
          .get(key, "")
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  removeKeychain(key) {
    return new Promise((resolve, reject) => {
      this.keyChain
        .remove(key)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setSecureStorageVal(key, value, callDelay?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (callDelay) {
        setTimeout(() => {
          this.storageObj.set(key, value).then(
            (data) => resolve("done"),
            (error) => reject("err")
          );
        }, 1500);
      } else {
        this.storageObj.set(key, value).then(
          (data) => resolve("done"),
          (error) => reject("err")
        );
      }
    });
  }

  getSecureStorageVal(key, callDelay?: any): Promise<any> {
    console.log("reached getSecureStorageVal");
    return new Promise((resolve, reject) => {
      if (callDelay) {
        setTimeout(() => {
          this.storageObj
            .get(key)
            .then((data) => resolve(data))
            .catch((err) => {
              console.log("getSecureStorageVal err", err);
              reject(err);
            });
        }, 1500);
      } else {
        this.storageObj
          .get(key)
          .then((data) => resolve(data))
          .catch((err) => {
            console.log("getSecureStorageVal err", err);
            reject(err);
          });
      }
    });
  }

  removeSecureObj(key) {
    return new Promise((resolve, reject) => {
      this.storageObj
        .remove(key)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  storeSensitiveData(keyname, data, callDelay?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.currentPlatform == "android") {
        this.setSecureStorageVal(keyname, data, callDelay)
          .then((stored) => {
            resolve("done");
          })
          .catch((errStoring) => {
            reject(errStoring);
          });
      } else if (this.currentPlatform == "ios") {
        this.setKeychain(keyname, data, callDelay)
          .then((stored) => {
            resolve("done");
          })
          .catch((errStoring) => {
            reject(errStoring);
          });
      } else {
        localStorage.setItem(keyname, data);
        resolve("done");
      }
    });
  }

  retrieveSensitiveData(keyName,platform?: any, callDelay?: any): Promise<any> {
      // console.log("reached retrieveSensitiveData", keyName, platform, callDelay);
    this.currentPlatform = platform;
    return new Promise((resolve, reject) => {
      if (this.currentPlatform == "android") {
        this.getSecureStorageVal(keyName, callDelay)
          .then((storedData) => {
            resolve(storedData);
          })
          .catch((errReading) => {
            reject(errReading);
          });
      } else if (this.currentPlatform == "ios") {
        this.platform.ready().then(() => {
          this.getKeychain(keyName, callDelay)
          .then((storedData) => {
            resolve(storedData);
          })
          .catch((errReading) => {
            reject(errReading);
          });
        });
      } else {
        resolve(localStorage.getItem(keyName));
      }
    });
  }

  clearSensitiveInfo(keyName): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.currentPlatform == "android") {
        this.removeSecureObj(keyName)
          .then((storedData) => {
            resolve(storedData);
          })
          .catch((errReading) => {
            reject("err");
          });
      } else if (this.currentPlatform == "ios") {
        this.removeKeychain(keyName)
          .then((storedData) => {
            resolve(storedData);
          })
          .catch((errReading) => {
            reject("err");
          });
      } else {
        localStorage.setItem(keyName, null);
        resolve("done");
      }
    });
  }
}
