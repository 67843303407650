import { Injectable } from "@angular/core";
import { Observable, throwError, from as fromPromise} from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, map, retry, timeout } from "rxjs/operators";
import { NetworkStatusService } from "./network-status.service";
import { Platform,ToastController } from "@ionic/angular";
import { HTTP } from "@ionic-native/http/ngx";
import { TranslateService } from '@ngx-translate/core';
import "rxjs/add/observable/fromPromise";
import { AppConfig } from "./app-config";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  errorMessage = "Something bad happened; Please try again later.";

  //   apiUrl = environment.apiUrl;

  httpOptions: {};

  headerdata: any;

  headers: any;
  analyticsheaders: any;
  authorization: string;

  /* httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.accessToken,
      'Accept': 'application/json'
    })
  }; */

  isLocal: boolean = false;
  isDevice: boolean = false;
  language = "en";
  severErrorMsg: "Internal server error, Please try again.";
  serviceUnavlaibleMsg: "Service Unavailable, Please try after sometime.";
  timeoutMsg: "Connection timeout, Please try again";
  toast: any;
  isAccessTokenExpired: any;
  currentPlatform: string;
  connectUserId:any;
  constructor(
    // private http: HttpClient,
    private networkService: NetworkStatusService,
    // private authService: MsalService,
    private platform: Platform,
    private httpAngular: HttpClient,
    private httpAdvanced: HTTP,
    private toastCtrl: ToastController,
    public appConfig: AppConfig
  ) {
    platform.ready().then(() => {
      if (platform.is("ios") || platform.is("android")) {
        this.isDevice = true;
      } else {
        this.isDevice = false;
      }
      
    });
    if (this.platform.is('android')) {
      this.currentPlatform = 'android';
    }
    else if (this.platform.is('ios')) {
      this.currentPlatform = 'ios';
    } else if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.currentPlatform = 'browser'
    }
    this.setHeadersHttpAngular();
    this.setHeadersHttpNative();
    let connectedUser = localStorage.getItem("connection");
    let data;
    try {
        data = JSON.parse(connectedUser);
    } catch(e) {
      console.log(e);
    }
    if(connectedUser && data) {
      if(data.userId === localStorage.getItem("fujiUserId")) {
        this.connectUserId = data.connectUserId;
      }else if(data.connectUserId === localStorage.getItem("fujiUserId")) {
        this.connectUserId = data.userId;
      }
    }
    // if(JSON.parse(localStorage.getItem("connection"))?.userId === localStorage.getItem("fujiUserId")){
    //   this.connectUserId = JSON.parse(localStorage.getItem("connection"))?.connectUserId;
    // }else if(JSON.parse(localStorage.getItem("connection"))?.connectUserId === localStorage.getItem("fujiUserId")){
    //   this.connectUserId = JSON.parse(localStorage.getItem("connection"))?.userId;
    // }
    // console.log('##this.connectUserId',this.connectUserId)
    // this.isLocal = (environment.msalConfig.auth.redirectUri.indexOf('localhost') >= 0);
  }

  isConnectedUser() {
    let c = localStorage.getItem('isConnectedUser');
    if(c && c === "1") {
      return true;
    }
    return false;
  }
  setAuthorization(authData) {
    console.log("setAuthorization called",authData);
    if (authData !== "remove") {
      this.authorization = authData;
    } else {
      this.authorization = null;
    }
  }

  showToastWithoutAction(message) {
    this.toast = this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: 'bottom',
        color: 'danger'
    }).then((toastData) => {
        console.log('in toast');
        console.log(toastData);
        toastData.present();
    });
}

  checkLanguage()
  {
    if(localStorage.getItem('setLanguage'))
    {
    this.language=localStorage.getItem('setLanguage');
    }
  }

  // postDetailsForKeyCloak(url: string, data: any): Observable<any> {
  //   console.log("URL: " + url);
  //   const headerOption = this.getHeadersForKeyCloack("post");
  //   console.log(" url  data header option",url, data, headerOption);
  //   // const headerOption = {};
  //   // return fromPromise(this.isInternetConnected()).pipe(
  //   //   mergeMap((intConn) => {
  //       // console.log("intConn---", intConn);
  //       // if (intConn === "INT_ERR") {
  //       //   console.log("INT_ERR");
  //       //   return throwError("INT_ERR");
  //       // } else {
  //         return this.http.post(url, data, this.headers).pipe(
  //           map(this.extractData),
  //           catchError((error) => {
  //             console.log("Error in postDetails -- ", error);
  //             console.log("Error status -- ", error.status);
  //             console.log("Current window location --", window.location);
  //             if (
  //               (error.status === 401 ||
  //                 error.status === 403 ||
  //                 error.status === 500 ||
  //                 error.status === 0) &&
  //               (localStorage.getItem("organizerId") != null ||
  //                 localStorage.getItem("organizerId") != undefined)
  //             ) {
  //               return throwError("Unauthorized");
  //             } else {
  //               return throwError(this.errorMessage);
  //             }
  //           })
  //      //   );
  //     //  }
  //  //   })
  //   );
  // }

  postDetailsForDeviceKeyCloak(url: string, data: any): Promise<any> {
    const headerOption = this.getHeadersForKeyCloackDevice("post");
    return new Promise((resolve, reject) => {
      // this.httpAdvanced.setDataSerializer("urlencoded");
      console.log("postDetailsForDeviceKeyCloak headerOptions", headerOption, data, url);
      return this.httpAdvanced
        .post(url, data, headerOption)
        .then((res) => {
          console.log("Success in postDetailsForDeviceKeyCloak",res);
          resolve(JSON.parse(res.data));
        })
        .catch((error) => {
          console.log("Error in postDetailsForDeviceKeyCloak",error);
          if (
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 0) &&
            (localStorage.getItem("organizerId") != null ||
              localStorage.getItem("organizerId") != undefined)
          ) {
            reject(throwError("Unauthorized"));
          }else if(error.status === 500){
            this.showToastWithoutAction(this.severErrorMsg);
          }else if(error.status === 503){
            this.showToastWithoutAction(this.serviceUnavlaibleMsg);
          }else if(error.status === 504){
            this.showToastWithoutAction(this.timeoutMsg);
          } 
           else {
            reject(throwError(this.errorMessage));
          }
        });
    });
    //  });
    // });
  }

  postDetailsForDeviceKeyCloakLogout(url: string, data: any): Promise<any> {
    const headerOption = this.getHeadersForKeyCloackDevice("post");
    return new Promise((resolve, reject) => {
      this.httpAdvanced.setDataSerializer("urlencoded");
      return this.httpAdvanced
        .post(url, data, headerOption)
        .then((res) => {
          console.log("Success in postDetailsForDeviceKeyCloak",res);
          resolve(res.data);
        })
        .catch((error) => {
          console.log("Error in postDetailsForDeviceKeyCloak",error);
          if (
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 0) &&
            (localStorage.getItem("organizerId") != null ||
              localStorage.getItem("organizerId") != undefined)
          ) {
            reject(throwError("Unauthorized"));
          }else if(error.status === 500){
            this.showToastWithoutAction(this.severErrorMsg);
          }else if(error.status === 503){
            this.showToastWithoutAction(this.serviceUnavlaibleMsg);
          }else if(error.status === 504){
            this.showToastWithoutAction(this.timeoutMsg);
          }  else {
            reject(throwError(this.errorMessage));
          }
        });
    });
    //  });
    // });
  }

  postDetailsForDevice(url: string, data: any): Promise<any> {
    const headerOption = this.getHeadersForDevice("post");
    // const headerOption = {};
    return new Promise((resolve, reject) => {
      // this.isInternetConnected().then((intConn) => {
      //   console.log("intConn---", intConn);
      //   if (intConn === "INT_ERR") {
      //     console.log("INT_ERR");
      //     reject("INT_ERR");
      //   } else {
      //this.httpAdvanced.setDataSerializer("urlencoded");
      this.httpAdvanced.setDataSerializer("json");
      return this.httpAdvanced
        .post(url, data, headerOption)
        .then((res) => {
          console.log("http res post before parse",res);
          console.log("http res.data post before parse",res.data);
          resolve(JSON.parse(res.data));
        })
        .catch((error) => {
          console.log("inside error postDetailsForDeviceWithToken", error);
          if (
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 0) &&
            (localStorage.getItem("organizerId") != null ||
              localStorage.getItem("organizerId") != undefined)
          ) {
            reject(throwError("Unauthorized"));
          } else if(error.status === 500){
            this.showToastWithoutAction(this.severErrorMsg);
          }else if(error.status === 503){
            this.showToastWithoutAction(this.serviceUnavlaibleMsg);
          }else if(error.status === 504){
            this.showToastWithoutAction(this.timeoutMsg);
          } else {
            reject(error);
          }
        });
    });
    //  });
    // });
    
  }
  // private extractData(res) {
  //   return res || {};
  // }

  postDetailsForDeviceWithToken(url: string, data: any): Promise<any> {

    console.log("inside postDetailsForDeviceWithToken");
    const headerOption = this.getHeadersForDeviceWithToken("post");
    // const headerOption = {};
    return new Promise((resolve, reject) => {
      
      console.log("inside promise of postDetailsForDeviceWithToken");
      this.httpAdvanced.setDataSerializer("json");
      console.log("data",data);
      console.log("header options", headerOption);
      console.log("URL",url);
      //data = JSON.parse(data);
      return this.httpAdvanced
        .post(url, data , headerOption)
        .then((res) => {
          console.log("http res post before parse",res);
          console.log("http res.data post before parse",res.data);
          resolve(JSON.parse(res.data));
        })
        .catch((error) => {
          console.log("inside error postDetailsForDeviceWithToken", error);
          if (
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 0) &&
            (localStorage.getItem("organizerId") != null ||
              localStorage.getItem("organizerId") != undefined)
          ) {
            reject(throwError("Unauthorized"));
          } else if(error.status === 500){
            this.showToastWithoutAction(this.severErrorMsg);
          }else if(error.status === 503){
            this.showToastWithoutAction(this.serviceUnavlaibleMsg);
          }else if(error.status === 504){
            this.showToastWithoutAction(this.timeoutMsg);
          } else {
            // reject(throwError(this.errorMessage));
            reject(error);
          }
        });
    });
    //  });
    // });
    
  }

  postDetailsForSharing(url: string, data: any): Promise<any> {

    console.log("inside postDetailsForDeviceWithToken");
    const headerOption = this.getHeadersForDeviceWithToken("post");
    // const headerOption = {};
    return new Promise((resolve, reject) => {
      
      console.log("inside promise of postDetailsForDeviceWithToken");
      this.httpAdvanced.setDataSerializer("utf8");
      //this.httpAdvanced.setDataSerializer("json");
      console.log("data",data);
      console.log("header options", headerOption);
      console.log("URL",url);
      //data = JSON.parse(data);
      return this.httpAdvanced
        .post(url, data , headerOption)
        .then((res) => {
          console.log("http res post before parse",res);
          console.log("http res.data post before parse",res.data);
          resolve(JSON.parse(res.data));
        })
        .catch((error) => {
          console.log("inside error postDetailsForDeviceWithToken", error);
          if (
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 0) &&
            (localStorage.getItem("organizerId") != null ||
              localStorage.getItem("organizerId") != undefined)
          ) {
            reject(throwError("Unauthorized"));
          } else if(error.status === 500){
            this.showToastWithoutAction(this.severErrorMsg);
          }else if(error.status === 503){
            this.showToastWithoutAction(this.serviceUnavlaibleMsg);
          }else if(error.status === 504){
            this.showToastWithoutAction(this.timeoutMsg);
          } else {
            reject(error);
          }
        });
    });
    //  });
    // });
    
  }


  putDetailsForDevice(url: string, data: any, preLogin?): Promise<any> {
    const headerOption = this.getHeadersForDeviceWithToken("put");
    // const headerOption = {};
    if(!data) data = [];
    return new Promise((resolve, reject) => {
      if(url.includes("user/alertDuration")){
        this.httpAdvanced.setDataSerializer("utf8");
      }else{
        this.httpAdvanced.setDataSerializer("json");
      }
      console.log("data",JSON.stringify(data));
      console.log("header options", JSON.stringify(headerOption));
      console.log("URL",url);
      return this.httpAdvanced
        .put(url, data, preLogin ? null : headerOption)
        .then((res) => {

          console.log("http res before parse",res);
          console.log("http res.data before parse",res.data);
          resolve(JSON.parse(res.data));
        })
        .catch((error) => {
          console.log("error in http put",JSON.stringify(error));
          if (
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 0) &&
            (localStorage.getItem("organizerId") != null ||
              localStorage.getItem("organizerId") != undefined)
          ) {
            reject(throwError("Unauthorized"));
          } else if(error.status === 500){
            this.showToastWithoutAction(this.severErrorMsg);
          }else if(error.status === 503){
            this.showToastWithoutAction(this.serviceUnavlaibleMsg);
          }else if(error.status === 504){
            this.showToastWithoutAction(this.timeoutMsg);
          } else {
            reject(error);
          }
        });
    });
    //  });
    // });
    
  }



  putDetailsForDeviceNameChange(url: string, data: string): Promise<any> {
    const headerOption = this.getHeadersForDeviceWithToken("put");
    // const headerOption = {};
    return new Promise((resolve, reject) => {
      
      this.httpAdvanced.setDataSerializer("utf8");
      console.log("data",data);
      console.log("header options", headerOption);
      console.log("URL",url);
      return this.httpAdvanced
        .put(url, data, headerOption)
        .then((res) => {

          console.log("http res before parse",res);
          console.log("http res.data before parse",res.data);
          resolve(JSON.parse(res.data));
        })
        .catch((error) => {
          console.log("error in http put",error);
          if (
            (error.status === 401 ||
              error.status === 403 ||
              error.status === 0) &&
            (localStorage.getItem("organizerId") != null ||
              localStorage.getItem("organizerId") != undefined)
          ) {
            reject(throwError("Unauthorized"));
          } else if(error.status === 500){
            this.showToastWithoutAction(this.severErrorMsg);
          }else if(error.status === 503){
            this.showToastWithoutAction(this.serviceUnavlaibleMsg);
          }else if(error.status === 504){
            this.showToastWithoutAction(this.timeoutMsg);
          } else {
            reject(throwError(this.errorMessage));
          }
        });
    });
    //  });
    // });
    
  }



  getHeadersForKeyCloack(method) {
    var headerOption;
    if (this.isDevice) {
      if (method === "get") {
        headerOption = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };
      } else if (method === "post") {
        headerOption = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };
      }
    } else {
      if (method === "get") {
        headerOption = {
          headers: new HttpHeaders({
            // "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          }),
        };
      } else if (method === "post") {
        this.headers = {
          headers: new HttpHeaders({
            // "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          }),
        };

        // headerOption = {
        //   headers: new HttpHeaders({
        //     "Content-Type": "application/x-www-form-urlencoded",
        //     Accept: "application/json",
        //   }),
        // };
      } else {
        headerOption = {
          headers: new HttpHeaders({
            // "Content-Type": "application/x-www-form-urlencoded",
          }),
        };
      }
    }
    return headerOption;
  }

  getHeaders(method) {
    var headerOption;
    if (this.isDevice) {
      if (method === "get") {
        headerOption = {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        };
      } else if (method === "post") {
        headerOption = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };
      }
    } else {
      if (method === "get") {
        headerOption = {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          }),
        };
      } else if (method === "post") {
        this.headers = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Accept: "application/json",
          }),
          
        };

        // headerOption = {
        //   headers: new HttpHeaders({
        //     "Content-Type": "application/x-www-form-urlencoded",
        //     Accept: "application/json",
        //   }),
        // };
      } else {
        headerOption = {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        };
      }
    }
    return headerOption;
  }

  getHeadersForKeyCloackDevice(method) {
    var headerOption;
    if (method === "get") {
      headerOption = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    } else if (method === "post") {
      headerOption = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
    if(this.isConnectedUser()) headerOption["impersonate-id"] = this.connectUserId;
    return headerOption;
  }

  getHeadersForDevice(method) {
    var headerOption;
    if (method === "get") {
      headerOption = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      };
    } else if (method === "post") {
      // headerOption = {
      //   "Content-Type": "application/x-www-form-urlencoded",
      //   Accept: "application/json",
      // };
      headerOption = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
    if(this.isConnectedUser()) headerOption["impersonate-id"] = this.connectUserId;
    return headerOption;
  }

  getHeadersForDeviceWithToken(method) {
    var headerOption;
    console.log("inside getHeadersForDeviceWithToken");
    if (method === "get" || method == "delete") {
      headerOption = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: "Bearer " + this.authorization
      };
      if(this.isConnectedUser()) headerOption["impersonate-id"] = this.connectUserId;
    } else if (method === "post"|| method === "put") {
      // headerOption = {
      //   "Content-Type": "application/x-www-form-urlencoded",
      //   Accept: "application/json",
      // };
      headerOption = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.authorization
      };
      if(this.isConnectedUser()) headerOption["impersonate-id"] = this.connectUserId;
    }
    
    return headerOption;
  }

  getHeadersForDeviceWithToken2(method) {
    var headerOption;
    console.log("inside getHeadersForDeviceWithToken");
    if (method === "get") {
      headerOption = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: "Bearer " + this.authorization
      };
    } else if (method === "post" || method === "put") {
      // headerOption = {
      //   "Content-Type": "application/x-www-form-urlencoded",
      //   Accept: "application/json",
      // };
      headerOption = {
        "Content-Type": "text/plain; charset=utf-8",
        Accept: "text/plain; charset=utf-8",
        Authorization: "Bearer " + this.authorization
        };
    }
    if(this.isConnectedUser()) headerOption["impersonate-id"] = this.connectUserId;
    return headerOption;
  }

  /********  Http Service for Get method both browsers and mobile devices ************/

  httpGetApi(url): Observable<any> {
    console.log("httpGetApi Invoked");
    if (url.includes("assets/json")) {
      if (window.hasOwnProperty("cordova")) {
        return Observable.fromPromise(this.httpGetLocalOnDevice(url)).pipe(
          retry(2),
          catchError((err)=>{
            return err;
          })
        );;
      } else {
        return this.httpAngular
          .get(url, this.httpOptions)
          .pipe(timeout(40000))
          .pipe(map((res) => res))
          .pipe(
            retry(2),
            catchError((err)=>{
              return err;
            })
          );
      }
    } else if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(this.httpGetNative(url)).pipe(
      );
    } else {
      localStorage.setItem("userActiveTime",new Date().getTime().toString());
      this.setHeadersHttpAngular(url);
      return this.httpAngular
        .get(url, this.httpOptions)
        .pipe(timeout(40000))
        .pipe(map((res) => {
          console.log("Res recevied", res);
          return res;
        }))
        .pipe(
          retry(2),
          catchError((err)=>{
            return err;
          })
        );
    }
  }

  httpGetApiDataExport(url): Observable<any> {
    console.log("httpGetApiDataExport Invoked");
    const headers = new HttpHeaders({Authorization: "Bearer " + this.authorization}).set('Content-Type', 'text/plain; charset=utf-8');
    if (url.includes("assets/json")) {
      if (window.hasOwnProperty("cordova")) {
        return Observable.fromPromise(this.httpGetLocalOnDevice(url)).pipe(
          retry(2),
          catchError((err)=>{
            return err;
          })
        );;
      } else {
        return this.httpAngular
          .get(url,{ headers, responseType: 'text'})
          .pipe(timeout(40000))
          .pipe(map((res) => res))
          .pipe(
            retry(2),
            catchError((err)=>{
              return err;
            })
          );
      }
    } else if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(this.httpGetNativeDataExport(url)).pipe(
        retry(2),
        catchError((err)=>{
          return err;
        })
      );;
    } else {
      return this.httpAngular
        .get(url,{ headers, responseType: 'text'})
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return err;
          })
        );
    }
  }

  httpGetApiDataExport2(url): Observable<any> {
    console.log("httpGetApiDataExport2 Invoked");
    const headers = new HttpHeaders({Authorization: "Bearer " + this.authorization}).set('Content-Type', 'application/x-www-form-urlencoded').set("accept-language",localStorage.getItem("setLanguage"));
    if (url.includes("assets/json")) {
      if (window.hasOwnProperty("cordova")) {
        return Observable.fromPromise(this.httpGetLocalOnDevice(url)).pipe(
          retry(2),
          catchError((err)=>{
            return err;
          })
        );;
      } else {
        return this.httpAngular
          .get(url,{ headers, observe: 'response', responseType: 'text'})
          .pipe(timeout(40000))
          .pipe(map((res) => res))
          .pipe(
            retry(2),
            catchError((err)=>{
              return err;
            })
          );
      }
    } else if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(this.httpGetNativeDataExport(url)).pipe(
        retry(2),
        catchError((err)=>{
          return err;
        })
      );;
    } else {
      return this.httpAngular
        .get(url,{ headers, observe: 'response', responseType: 'arraybuffer'})
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return err;
          })
        );
    }
  }

  httpGetApiDataExport2Mob(url, path?): Observable<any> {
    console.log("K");
    const headers = new HttpHeaders({Authorization: "Bearer " + this.authorization}).set('Content-Type', 'application/x-www-form-urlencoded');
    if (url.includes("assets/json")) {
      if (window.hasOwnProperty("cordova")) {
        return Observable.fromPromise(this.httpGetLocalOnDevice(url));
      } else {
        return this.httpAngular
          .get(url,{ headers, responseType: 'text'})
          .pipe(timeout(40000))
          .pipe(map((res) => res))
          .pipe(
            retry(2),
            catchError((err)=>{
              return throwError(err);
            })
          );
      }
    } else if (window.hasOwnProperty("cordova")) {
      console.log("L");
      return Observable.fromPromise(this.httpGetNativeDataExportMob(url,path));
    } else {
      return this.httpAngular
        .get(url,{ headers, responseType: 'arraybuffer'})
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }
  httpGetApiWithoutHeader(url): Observable<any> {
    console.log("httpGetApiWithoutHeader Invoked");
    if (url.includes("assets/json")) {
      if (window.hasOwnProperty("cordova")) {
        return Observable.fromPromise(this.httpGetLocalOnDevice(url)).pipe(
          retry(2),
          catchError((err)=>{
            return err;
          })
        );;
      } else {
        return this.httpAngular
          .get(url)
          .pipe(timeout(40000))
          .pipe(map((res) => res))
          .pipe(
            retry(2),
            catchError((err)=>{
              return throwError(err);
            })
          );
      }
    } else if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(this.httpGetNativeWithoutHeader(url));
    } else {
      return this.httpAngular
        .get(url)
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }

  httpGetLocalOnDevice(url): Promise<any> {
    return fetch(url).then((res) => res.json());
  }

  httpGetNative(url): Promise<any> {
    this.setHeadersHttpNative(url);
    return this.httpAdvanced.get(url, {}, this.headerdata).catch(error =>{
      console.log("###### Error in httpGetNative", error);
      if(error.status == 401){
       this.isAccessTokenExpired = true;
      }
    });
  }

  httpGetNativeDataExport(url): Promise<any> {
    this.setHeadersHttpNativeDataExport();
    console.log("header", this.headerdata);
  return new Promise(async (resolve, reject) => {
    // this.httpAdvanced.get(url, {}, this.headerdata).then(res=>
    //   {
    //     console.log("httpgetnative data exportn res",res);
    //     resolve(res)
    //   }
    this.httpAdvanced.get(url, {}, this.headerdata).then(res=>
      {
      
      console.log("httpgetnative data exportn res httpGetNativeDataExport",res);
      
      resolve(res)
      
      }
).catch(err=>
  {
    console.log("httpgetnattive data export err",err);
    reject(err)
  }
) 
})
}
httpGetNativeDataExportMob(url,filePath?): Promise<any> {
  this.setHeadersHttpNativeDataExportMob();
return new Promise(async (resolve, reject) => {
  this.httpAdvanced.downloadFile(url, {}, this.headerdata,filePath).then(res=>
    {
      console.log("httpgetnative data exportn res httpGetNativeDataExportMob",res);
      resolve(res)
    }
).catch(err=>
{
  console.log("httpgetnattive data export err",err);
  reject(err)
}
) 
})
}
  httpGetNativeWithoutHeader(url): Promise<any> {
    

    return new Promise(async (resolve, reject) => {
      this.httpAdvanced.get(url, {}, null).then(res=>
        {
          console.log("httpgetnative data exportn res httpGetNativeWithoutHeader",res);
        resolve(res);
        }
    ).catch(err=>
    {
      console.log("httpgetnattive data export err",err);
      reject(err);
    }
    ) 
    })
  }

  httpDeleteNative(url): Promise<any> {
    this.setHeadersHttpNative();
    
    return this.httpAdvanced.delete(url, {} , this.headerdata);
  }

  setHeadersHttpAngular(url?) {
    this.checkLanguage();
    var headersToSend = new HttpHeaders();
    headersToSend = headersToSend
      .set("Content-Type", "application/json")
      .set("Accept-Language", this.language,);
    if(this.isConnectedUser() && !url?.includes("/api/v1/connection")){
      headersToSend = headersToSend.set("impersonate-id",this.connectUserId);
    }
    if(!url?.includes("/self-service/user/support-site-url")){
      headersToSend = headersToSend.set("Authorization", "Bearer " + this.authorization);
    }
    this.httpOptions = {
      headers: headersToSend
    };
    
  }

    setHeadersHttpNative(url?) {
       this.checkLanguage();
    this.headerdata = {
      "Content-Type": "application/json",
      "Accept-Language": this.language
    };
    if(!url?.includes("/self-service/user/support-site-url")){
      this.headerdata["Authorization"] = "Bearer " + this.authorization
    }
    if(this.isConnectedUser() && !url?.includes("/api/v1/connection")) this.headerdata["impersonate-id"] = this.connectUserId;
  }

  // setHeadersHttpNativeDataExport() {
  //   this.headerdata = {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //     "Content-Disposition": 'attachment; filename="INFO_GH_MiniTomato_multiple_20210819-012230.ZIP"',
  //     Authorization: "Bearer " + this.authorization,
  //     Accept: "*/*",
  //     "Accept-Language": "*/*",
  //     "Accept-Encoding":'gzip, deflate, br',
  //     responseType: 'arraybuffer'

  //   };
  // }

  setHeadersHttpNativeDataExport() {
    
    this.headerdata = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + this.authorization,
      Accept: "*/*",
      "Accept-Language": this.language,
      // "Accept-Encoding":'*',
      // "responseType": 'arraybuffer'
    };
    }

    setHeadersHttpNativeDataExportMob() {
    console.log("N");
    
      this.headerdata = {
        Authorization: "Bearer " + this.authorization,
        Accept: "*/*",
        "Accept-Language": this.language,
        // "Accept-Encoding":'*',
        // "responseType": 'arraybuffer'
      };
      }

  /*******  Http Service for POST method both browsers and mobile devices for LOGIN API *************/

  httpPostApiLogin(url, postData): Observable<any> {
    // if (window.hasOwnProperty("cordova")) {
    //   return Observable.fromPromise(
    //     this.postDetailsForDeviceKeyCloak(url, postData)
    //   );
    // } else {
      this.getHeadersForKeyCloack("post");
      // this.setHeadersHttpAngular();
      console.log(this.headers);
      console.log("##ur",url);
      if(url.includes("public/auth/token")){
        return this.httpAngular
        .post(url, postData, this.headers)
        .pipe(timeout(40000))
        .pipe(map((res) => res))  
        .pipe(
          catchError((err)=>{
            return throwError(err);
          })
        );
      }else{
        return this.httpAngular
        .post(url, postData, this.headers)
        .pipe(timeout(40000))
        .pipe(map((res) => res))  
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
      }
      
    // }
  }

  httpPostApiLogout(url, postData): Observable<any> {
    // if (window.hasOwnProperty("cordova")) {
    //   return Observable.fromPromise(
    //     this.postDetailsForDeviceKeyCloakLogout(url, postData)
    //   );
    // } else {
      this.getHeadersForKeyCloack("post");
      // this.setHeadersHttpAngular();
      return this.httpAngular
        .post(url, postData, this.headers)
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    // }
  }

  //Http service for POST method for both login and mobile devices */

  httpPostApi(url, postData): Observable<any> {
    if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(
        this.postDetailsForDevice(url, postData)
      );
    } else {
      this.getHeaders("post");
      // this.setHeadersHttpAngular();
      return this.httpAngular
        .post(url, postData, this.headers)
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }
  httpPostApiWithToken(url,postData): Observable<any> {
    console.log("Inside httpPostApiWithToken");
    if (window.hasOwnProperty("cordova")) {
      console.log("Inside httpPostApiWithToken Cordova condition");
      return Observable.fromPromise(
        this.postDetailsForDeviceWithToken(url, postData)
      );
    } else {
      this.getHeaders("post");
      // console.log("headerOption",headerOption);

      console.log("this.headers", this.headers);
      this.setHeadersHttpAngular(url);
      return this.httpAngular
        .post(url, postData, this.httpOptions)
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }

  httpPostForSharing(url,postData): Observable<any> {
    console.log("Inside httpPostApiWithToken");
    if (window.hasOwnProperty("cordova")) {
      console.log("Inside httpPostApiWithToken Cordova condition");
      return Observable.fromPromise(
        this.postDetailsForSharing(url, postData)
      );
    } else {
      this.getHeaders("post");
      // console.log("headerOption",headerOption);
      console.log("AJJJJAYYY");
      console.log("this.headers", this.headers);
      this.setHeadersHttpAngular();
      return this.httpAngular
        .post(url, postData, this.httpOptions)
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }
  
  /*******  Http Service for POST method both browsers and mobile devices for Anlytics API *************/
  httpPostApiAnalytics(url, postData): Observable<any> {
    if (window.hasOwnProperty("cordova")) {
      // return Observable.fromPromise(
      //   this.httpPostNativeAnalytics(url, postData)
      // );
      this.getHeadersForAnalytics();
      return this.httpAngular
        .post(url, postData, this.analyticsheaders)
        .pipe(timeout(40000))
        .pipe(map((res) => res));
    
    } else {
      this.getHeadersForAnalytics();
      return this.httpAngular
        .post(url, postData, this.analyticsheaders)
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }

  httpPostNativeAnalytics(url, postData): Promise<any> {
    this.httpAdvanced.setDataSerializer("json");
    this.getHeadersForAnalytics();
    return this.httpAdvanced.post(url, postData, this.headerdata);
  }
  getHeadersForAnalytics() {
    this.analyticsheaders = {
      headers: new HttpHeaders({
        "requestId": "5523ea78-a690-11eb-bcbc-0242ac130002",
        "Content-Type": "application/json"
      }),
    };
  }
  // ************************************************************************************* //
  //Not yet used till now
  httpPostNative(url, postData): Promise<any> {
    this.httpAdvanced.setDataSerializer("json");
    this.setHeadersHttpNative(url);
    return this.httpAdvanced.post(url, postData, this.headerdata);
  }

   /* Http sevice for Put API  */

   httpPutApi(url, postData?, preLogin = false): Observable<any> {
    if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(
        this.putDetailsForDevice(url, postData, preLogin)
      );
    } else {
      this.setHeadersHttpAngular();
      // this.setHeadersHttpAngular();
      return this.httpAngular
        .put(url, postData, preLogin ? {} : this.httpOptions)
        .pipe(timeout(40000))
        .pipe(map((res) => res));
    }
  }

  httpPutApiNameChange(url, postData): Observable<any> {

    if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(
        this.putDetailsForDeviceNameChange(url, postData)
      );
    } else {
      this.setHeadersHttpAngular();
      // this.setHeadersHttpAngular();
      return this.httpAngular
        .put(url, postData, this.httpOptions)
        .pipe(timeout(40000))
        .pipe(map((res) => res))
        .pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }

  /* Http sevice for Delete API  */

  httpDeleteApi(url): Observable<any> {

    if (url.includes("assets/json")) {
      if (window.hasOwnProperty("cordova")) {
        return Observable.fromPromise(this.httpGetLocalOnDevice(url));
      } else {
        // return this.httpAngular
        //   .get(url, this.httpOptions)
        //   .pipe(timeout(40000))
        //   .pipe(map((res) => res));
        return this.httpAngular
          .delete(url, this.httpOptions)
          .pipe(timeout(40000))
          .pipe(map((res) => res))
          .pipe(
            retry(2),
            catchError((err)=>{
              return throwError(err);
            })
          );
      }
    } else if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(this.httpDeleteNative(url));
    } else {
      this.setHeadersHttpAngular();
      // return this.httpAngular
      //   .get(url, this.httpOptions)
      //   .pipe(timeout(40000))
      //   .pipe(map((res) => res));
      return this.httpAngular
        .delete(url, this.httpOptions)
        .pipe(timeout(40000))
        .pipe(map((res) => res)).pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }
  httpDeleteApiToken(url): Observable<any> {
    const headerOption = this.getHeadersForDeviceWithToken("delete");
    console.log("headerOption",JSON.stringify(headerOption));
    if (url.includes("assets/json")) {
      if (window.hasOwnProperty("cordova")) {
        return Observable.fromPromise(this.httpGetLocalOnDevice(url));
      } else {
        // return this.httpAngular
        //   .get(url, this.httpOptions)
        //   .pipe(timeout(40000))
        //   .pipe(map((res) => res));
        return this.httpAngular
          .delete(url, headerOption)
          .pipe(timeout(40000))
          .pipe(map((res) => res))
          .pipe(
            retry(2),
            catchError((err)=>{
              return throwError(err);
            })
          );
      }
    } else if (window.hasOwnProperty("cordova")) {
      return Observable.fromPromise(this.httpDeleteNative(url));
    } else {
      this.setHeadersHttpAngular();
      // return this.httpAngular
      //   .get(url, this.httpOptions)
      //   .pipe(timeout(40000))
      //   .pipe(map((res) => res));
      return this.httpAngular
        .delete(url, headerOption)
        .pipe(timeout(40000))
        .pipe(map((res) => res)).pipe(
          retry(2),
          catchError((err)=>{
            return throwError(err);
          })
        );
    }
  }
}
