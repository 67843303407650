import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginStatus: boolean;

  constructor() { 
    let status = localStorage.getItem("isUserLoggedIn")
    this.loginStatus = status !=null && status === "true";
  }

  isLoggedin() {
    return this.loginStatus;
  }

  setLoggedInStatus(status: string) {
    localStorage.setItem("isUserLoggedIn", status);
  }
}
