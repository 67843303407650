import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './http-service';
import { Utils } from './utils';
import { HttpClient, HttpClientModule, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { AlertService } from './alert-service';
import { HTTP } from '@ionic-native/http/ngx';
import { ToastService } from "../utils/toast-service";
import { TranslationService } from "../utils/translation-service";
import { NetworkStatusService } from "../utils/network-status.service";
import { AppConfig } from '../utils/app-config';
import { KeychainService } from '../utils/keychain-service';
import { Network } from '@ionic-native/network/ngx';
import { Keychain } from '@ionic-native/keychain/ngx';
import { SecureStorage, SecureStorageObject } from '@ionic-native/secure-storage/ngx';
import { Storage } from '@ionic/storage';

@NgModule({
    imports: [
      CommonModule, 
      HttpClientModule
    ],
    declarations: [
   
  ],
    exports: [],
    providers: [SecureStorage, Storage, Keychain,Network, KeychainService, AppConfig, NetworkStatusService, TranslationService, HTTP,HttpService, AlertService, Utils, HttpClient,ToastService]
})
export class UtilsModule { }