import { Injectable } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';

@Injectable()
export class AlertService {
    onDevice: boolean;
    isAlertShown = false;
    constructor(private platform: Platform, private alertCtrl: AlertController) {
        this.onDevice = this.platform.is('cordova');
    }

    async showAlert(header, msg, mode, btn, subTitle?, cssClass?) {
        const alert = await this.alertCtrl.create({
            header: header,
            subHeader: (subTitle == null || subTitle === undefined || subTitle === '') ? '' : subTitle,
            mode: mode,
            message: msg,
            buttons: btn,
            cssClass: cssClass
        });
        await alert.present();
    }
    async createCustomAlert(type,message,action1,action2): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertCtrl.create({
            cssClass: 'my-custom-class',
                header: type,
                mode: "md",
                message: message,
            buttons: [
        
            {
                text: action1,
                role: 'delete',

                handler: () => {
                    resolve('delete');
                }
                }, {
                    text: action2,
                    role: 'cancel',
                    handler: () => {
                        resolve('cancel');
                    }
                },
            ]
        });
        alert.present();
        });
    }
    async confirmationAlert(type,message,action1,action2): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertCtrl.create({
                cssClass: 'my-custom-class',
                header: type,
                mode: "md",
                message: message,
                buttons: [

                    {
                        text: action1,
                        role: 'ok',

                        handler: () => {
                            resolve('ok');
                        }
                    }, {
                        text: action2,
                        role: 'cancel',
                        handler: () => {
                            resolve('cancel');
                        }
                    },
                ]
            });
            alert.present();
        });
    }
    async confirmationAlertWithOkOnly(header,message,buttonName): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertCtrl.create({
                cssClass: 'my-custom-class',
                header: header,
                message: message,
                mode: "md",
                backdropDismiss: false,
                buttons: [

                    {
                        text: buttonName,
                        role: 'ok',

                        handler: () => {
                            this.alertCtrl.dismiss().then(() => { 
                                this.isAlertShown = false;
                                resolve('ok'); 
                            });
                        }
                    },
                ],

            });
            if(!this.isAlertShown){
                alert.present();
                this.isAlertShown = true;
            }
        });
    }
    async alertMessageBeforeDelete(type,message,action1,action2): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.alertCtrl.create({
                header: type,
                mode: "md",
                message: message,
                buttons: [
                    {
                        text: action1,
                        role: 'cancel'
                    },
                    {
                        text: action2,
                        handler: () => {
                            this.alertCtrl.dismiss().then(() => { resolve(true); });
                        }
                    },
                ]
            }).then(alert => alert.present());
        });
    }
    async replaceAlertMessage(type,message,action1,action2): Promise<any>{
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertCtrl.create({
                cssClass: 'my-custom-class',
                header: type,
                mode: "md",
                message: message,
                buttons: [

                    {
                        text: action1,
                        role: 'replace',

                        handler: () => {
                            resolve('replace');
                        }
                    }, {
                        text: action2,
                        role: 'cancel',
                        handler: () => {
                            resolve('cancel');
                        }
                    },
                ]
            });
            alert.present();
        });
}
    async customAlertWithYesOption(type,message,action1,action2): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertCtrl.create({
                cssClass: 'my-custom-class',
                header: type,
                mode: "md",
                message: message,
                buttons: [

                    {
                        text: action1,
                        role: 'yes',

                        handler: () => {
                            resolve('yes');
                        }
                    }, {
                        text: action2,
                        role: 'cancel',
                        handler: () => {
                            resolve('cancel');
                        }
                    },
                ]
            });
            alert.present();
        });
    }
    async alertWithNoAction(type,message,action): Promise<any> {
        return new Promise(async (resolve, reject) => {
        const alert = await this.alertCtrl.create({
            cssClass: 'my-custom-class',
            header: type,
            mode: "md",
            message: message,
            buttons: [
                {
                    text: action,
                    role: 'ok',

                    handler: () => {
                        resolve('ok');
                    }
                }
                ]
        });

        await alert.present();
        });
    }
    async confirmationAlertWithYesorNo(type,message,action1,action2): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertCtrl.create({
                cssClass: 'my-custom-class',
                header: type,
                mode: "md",
                message: message,
                buttons: [

                    {
                        text: action1,
                        role: 'yes',

                        handler: () => {
                            resolve('yes');
                        }
                    }, {
                        text: action2,
                        role: 'no',
                        handler: () => {
                            resolve('no');
                        }
                    },
                ]
            });
            alert.present();
        });
    }
}