import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { env } from "process";
import { Utils } from "./utils";
@Injectable()
export class AppConfig {
  onDevice: boolean;
  authToken: "";
  loginUrl = "";
  appName = "";
  appVersion = "";
  appNameSpace = "";
  none: string = "none";
  analytics_api = "";
  currentPlatform: string;
  areaUnitValueStore: any;
  region = "";
  apiKeyGoogle = "AIzaSyCJL_G50-qSwB-2zcCQbd20pQwiKH9u_28";
  // browserLoginUrl = "https://cors-anywhere.herokuapp.com/";
  browserLoginUrl = "";
  localJsonPath = "assets/json/";
  environmentFileURL = "assets/json/env-config.json";
  plantectCardLocalJson = "plantectCard.json";
  tempgraph = "tempgraph.json";
  strawberryAlert = "strawberryAlert.json";
  greenhouse_summary_api = "";
  baseApi = "";
  sensorsData = "";
  logout_url = "";
  register_url = "";
  forgotPassword_url = "";
  baseUrl = "";
  unregister_url = "";
  update_email_url = "";
  getUserInfo_url = "";
  displayName_url = "";
  getEmailAlerts_url = "";
  updateEmailAlerts_url = "";
  changePassword_url = "";
  maxEmailLimit_url = "";
  AccessKeys_url = "";
  product_category_url = "";
  category_url = "";
  planting_types_url = "";
  cropseason_url = "";
  archives_url = "";
  gateway_url = "";
  greenhouse_url = "";
  dataexport_url = "";
  greenhouseData_url = "";
  getAllOpenCropSeasonsOfUser_url = "";
  getThresholdOfGreenHouse_url = "";
  getLatestDiseaseRisk = "";
  getSingleAlert = "";
  getAllAlerts = "";
  analyticsData = "";
  analytics_url = "";
  maxAnalyticsLine_url = "";
  analytics_ParamRange = "";
  cropseasonGreenhouse_url = "";
  getListCropSeasonDataType_url = "";
  getListVarietyType_url = "";
  getListVarietyTypeByCropTypeCode_url = "";
  csvUrl = "";
  cropUrl = "";
  sensoeUrl = "";
  cropSensorUrl = "";
  addSensorUrl = "";
  addGreenHouseUrl = "";
  addcalibrationUrl = "";
  editGreenhouseUrl = "";
  editSensorUrl = "";
  sensorDisplayScaleUrl = "";
  sensorCustomizedRangeUrl = "";
  getsensorCustomizedRangeUrl = "";
  optimumList_url = "";
  getAccumulatedTemperatureByGreenhouseId_url = "";
  getCropChemicalsUrl = "";
  postChemicalSprayUrl = "";
  calibrationValue = "";
  allDiseasesUrl = "";
  getsensorsByType = "";
  getCropTypesOfKorea = "";
  getRecentlyUsedChemicals = "";
  latestUpdate_api = "";
  supportNewIconURL="";
  support_license_URL="";
  diseaseTool_url = "";
  notification_lang_url = "";
  register_device_url="";
  delete_deviceToken_url="";
  alert_duration_url = "";
  email_connection_url=""
  email_invitation_url="";
  send_invite_url="";
  connected_users_url="";
  data_communication_url="";
  change_email_url="";
  analytics_user_connected_url="";
  line_register_url ="";
  line_revoke_url = "";
  location_prediction_url="";
  location_coordinates_url="";
  domain_url="";
  support_url="";
  // loginErr = "Login failed, please try after sometime!";
  // closeCropSeasonError = "Failed to close the season";
  // reopenError = "Cannot reopen Crop Season";
  // mandatoryDetails = "Please check all the mandatory details";
  // sharingErrMsg = "Cannot share name";
  // deleteSharingMsg = "Cannot delete user";
  // userRegisterred = "User has been successfully registered. You will shortly receive a mail with the activation link, kindly try to login after activating your account!";
  // addSensorErr = "Failed to add sensor";
  // addGatewayErr = "Failed to add gateway";
  // errMsg = "Some error occured!";
  // nameChangeSuccess = "Name changed succesful";
  // userUnregister = "User unregistered successfully!";
  // userUnregisterErr = "Some error occured during user unregistration!";
  // forgotUserErr = "Failed to reset password, kindly try after sometime.";
  // commonError = "Something went wrong please try again, Please try again";

  
  selectRegion = "Please select region. (Error code: C020001)";
  // networkMessage = "Please check your internet connection and try again.";
  sessionExipiredMessage = "Session expired, Please login again";
  // getErrorMessage = "Unable to fetch data, You internet connectivity seems to be slow. Please try again.";
  postErrorMessage =  localStorage.getItem("setLanguage") == "ja" ? "データの保存ができませんでした。もう一度お試しください。" :"Failed to save the data, Please try again";
  deleteGatewayErr = "Failed to delete Gateway, Please try again.";
  deleteGreenhouse = "Failed to delete Greenhouse, Please try again.";
  deleteSensor = "Failed to delete Sensor, Please try again.";
  emailElertsErr = "Unable to fetch user details, Please try again";
  sensorThresholdErr = "Unable to post Sensor threshold details, Please try again";
  userRegisterErr = "Some error occured during user registration! Please try again";
  validateCropseason = "Failed to validate crop seasons, Please try again";
  alertsErrMsg = "Failed to fetch alerts information, Please try again";
  changePasswordErr = "Failed to change password, Please try again";
  nameChangeErr = "Failed to change the name, Please try again";
  addGreenhouseErr = "Failed to save greenhouse data, Please try again";
  
  //csvUrl ="/api/v1/csv?greenHouse=";
  confirmationText: any;
  okText: any;
  constructor(private platform: Platform,) {
    // this.confirmationText = this.utils.translateText("UI.COMMON.CONFIRM");
    // this.okText = this.utils.translateText('UI.COMMON.OK_BUTTON');
    this.browserLoginUrl = "";
    // if (window.origin.includes("localhost")) {
    //   this.browserLoginUrl = "https://cors-anywhere.herokuapp.com/";
    // } else {
    //   this.browserLoginUrl = "";
    // }
    console.log("browserLoginUrl --", this.browserLoginUrl);
    if (this.platform.is("android")) {
      this.currentPlatform = "android";
    } else if (this.platform.is("ios")) {
      this.currentPlatform = "ios";
    } else if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
      this.currentPlatform = "browser";
    }
  }

  loadEnvironmentVars(envVars) {
    this.loginUrl = envVars.login_url;
    this.greenhouse_summary_api = envVars.greenhouse_summary_api;
    this.appName = envVars.appName;
    this.appVersion = envVars.app_version;
    this.appNameSpace = envVars.appNameSpace;
    this.analytics_api = envVars.analytics_api;
    this.baseApi = envVars.baseApi;
    this.sensorsData = envVars.sensorsData;
    this.logout_url = envVars.logout_url;
    this.register_url = envVars.register_url;
    this.forgotPassword_url = envVars.forgotPassword_url;
    this.unregister_url = envVars.unregister_url;
    this.update_email_url = envVars.updateEmail_url;
    this.product_category_url = envVars.product_category_url;
    this.category_url = envVars.category_url;
    this.baseUrl = envVars.baseUrl;
    this.getUserInfo_url = envVars.getUserInfo_url;
    this.displayName_url = envVars.displayName_url;
    this.getEmailAlerts_url = envVars.getEmailAlerts_url;
    this.changePassword_url = envVars.changePassword_url;
    this.maxEmailLimit_url = envVars.maxEmailLimit_url;
    this.AccessKeys_url = envVars.AccessKeys_url;
    this.planting_types_url = envVars.planting_types_url;
    this.cropseason_url = envVars.cropseason_url;
    this.archives_url = envVars.archives_url;
    this.gateway_url = envVars.gateway_url;
    this.greenhouse_url = envVars.greenhouse_url;
    this.dataexport_url = envVars.dataexport_url;
    this.greenhouseData_url = envVars.greenhouseData_url;
    this.getAllOpenCropSeasonsOfUser_url =
      envVars.getAllOpenCropSeasonsOfUser_url;
    this.getThresholdOfGreenHouse_url = envVars.getThresholdOfGreenHouse_url;
    this.cropseasonGreenhouse_url = envVars.cropseasonGreenhouse_url;
    this.getLatestDiseaseRisk = envVars.getLatestDiseaseRisk;
    this.getSingleAlert = envVars.getSingleAlert;
    this.getAllAlerts = envVars.getAllAlerts;
    this.latestUpdate_api = envVars.latestUpdate_api;
    this.diseaseTool_url = envVars.diseaseTool_url;
    this.analyticsData = envVars.analyticsData;
    this.analytics_url = envVars.analytics_url;
    this.maxAnalyticsLine_url = envVars.maxAnalyticsLine_url;
    this.analytics_ParamRange = envVars.analytics_ParamRange;
    this.getListCropSeasonDataType_url = envVars.getListCropSeasonDataType_url;
    this.getListVarietyType_url = envVars.getListVarietyType_url;
    this.getListVarietyTypeByCropTypeCode_url =
      envVars.getListVarietyTypeByCropTypeCode_url;
    this.csvUrl = envVars.csvUrl;
    this.cropUrl = envVars.cropUrl;
    this.sensoeUrl = envVars.sensoeUrl;
    this.cropSensorUrl = envVars.cropSensorUrl;
    this.addSensorUrl = envVars.addSensorUrl;
    this.addGreenHouseUrl = envVars.addGreenHouseUrl;
    this.addcalibrationUrl = envVars.addcalibrationUrl;
    this.editGreenhouseUrl = envVars.editGreenhouseUrl;
    this.editSensorUrl = envVars.editSensorUrl;
    this.sensorDisplayScaleUrl = envVars.sensorDisplayScaleUrl;
    this.sensorCustomizedRangeUrl = envVars.sensorCustomizedRangeUrl;
    this.getsensorCustomizedRangeUrl = envVars.getsensorCustomizedRangeUrl;
    this.optimumList_url = envVars.optimumList_url;
    this.getAccumulatedTemperatureByGreenhouseId_url =
      envVars.getAccumulatedTemperatureByGreenhouseId_url;
    this.getCropChemicalsUrl = envVars.getCropChemicalsUrl;
    this.postChemicalSprayUrl = envVars.postChemicalSprayUrl;
    this.allDiseasesUrl = envVars.allDiseasesUrl;
    this.calibrationValue = envVars.calibrationValue;
    this.getsensorsByType = envVars.getsensorsByType;
    this.getCropTypesOfKorea = envVars.getCropTypesOfKorea;
    this.getRecentlyUsedChemicals = envVars.getRecentlyUsedChemicals;
    this.supportNewIconURL = envVars.supportNewIconURL;
    this.support_license_URL = envVars.support_license_URL;
    this.notification_lang_url = envVars.notification_lang_url;
    this.register_device_url = envVars.register_device_url;
    this.delete_deviceToken_url = envVars.delete_deviceToken_url;
    this.alert_duration_url = envVars.alert_duration_url;
    this.email_connection_url = envVars.email_connection_url;
    this.email_invitation_url= envVars.email_invitation_url;
    this.send_invite_url = envVars.send_invite_url;
    this.connected_users_url = envVars.connected_users_url;
    this.data_communication_url = envVars.data_communication_url;
    this.change_email_url = envVars.change_email_url;
    this.analytics_user_connected_url=envVars.analytics_user_connected_url;
    this.line_register_url = envVars.line_register_url;
    this.line_revoke_url = envVars.line_revoke_url;
    this.location_prediction_url = envVars.location_prediction_url;
    this.location_coordinates_url = envVars.location_coordinates_url;
    this.domain_url = envVars.domain_url;
    this.support_url = envVars.support_url;
    // this.csvUrl = envVars.csvUrl;
  }
}
