import { Component, ChangeDetectorRef, OnInit, ViewChild, NgZone } from "@angular/core";
import { Utils } from "../utils/utils";
import { NavController } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverController, ModalController } from '@ionic/angular';
import { AppCenterCrashes } from '@ionic-native/app-center-crashes/ngx';
import { TranslateService } from '@ngx-translate/core';
import { MenuController } from "@ionic/angular";
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { HttpClient } from "@angular/common/http";
import { HttpService } from "src/utils/http-service";
import { Subscription } from "rxjs";
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { IonContent } from '@ionic/angular'
import { EventService } from "src/utils/event-service";


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  pageList: any = [];
  optionHeight = 39;
  settingsOptionHeight = 48;
  paddingLeft = 16;
  currentPlatform: string;
  secondpageList = [];
  thirdpageList = [];
  screenDimension: boolean;
  showOption: boolean;
  gh: any;
  optionTopMargin: number;
  settingsTopMargin: number;
  menuToggle: boolean = false;
  settingSubOpt: boolean;
  settingList: any;
  howerEffect: boolean = false;
  settingHowerEffect: boolean = false;
  username: any;
  region: any;
  supportNewLabel: boolean = false;
  isConnectedUser: any;
  routerSubscription: Subscription | undefined;
  isGHLoading = false;
  @ViewChild(NavController) navChild: NavController;
  @ViewChild('main', { static: false }) content: IonContent;
  // @ViewChild(Content) content: Content;
  isSwitchAccount = false;
  alertCount: number = 0;
  scrollPosition = 0;
  isOpen: boolean = false;
  previousScreenName: string;
  
  constructor(
    public utils: Utils,
    private modalController: ModalController,
    private translate: TranslateService,
    public navCtrl: NavController,
    private router: Router,
    private platform: Platform,
    public androidPermissions: AndroidPermissions,
    private menu: MenuController,
    private iab: InAppBrowser,
    private changeDetectorRef: ChangeDetectorRef,
    private appCenterCrashes: AppCenterCrashes,
    private statusBar: StatusBar,
    private httpClient: HttpClient,
    private httpService: HttpService,
    private zone: NgZone,
    private deeplinks: Deeplinks,
    private route: ActivatedRoute,
    private eventService: EventService
  ) {
    this.loadComponentData()
  }
  loadComponentData() {
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.statusBar.styleLightContent();
      }
      else if (this.platform.is('ios')) {
        // let status bar overlay webview
        this.statusBar.overlaysWebView(false);
        // set status bar to black
        this.statusBar.backgroundColorByHexString('#095E7F');
      }
      this.setupDeepLinks();
    });
    this.initializeApp();
    this.setPlatformListener();
    this.utils.initTranslate();

    // Support hidden untill CORS issue is resolved 

    // {
    //   iconName: "assets/icons_svg/ic_sidebar_userInfo.svg",
    //   displayText: "UI.COMMON.SUPPORT_SITE",
    //   expanded: false,
    //   hasChild: false,
    // },
    this.secondpageList = [
      {
        iconName: "assets/icons_svg/support-icon.svg",
        displayText: "UI.COMMON.SUPPORT_SITE",
        expanded: false,
        hasChild: false,
        supportLabel: true,
      },
      {
        iconName: "assets/icons_svg/sunshine.svg",
        displayText: "UI.COMMON.CROP_SEASON_MANAGEMENT",
        url: "/crop-season-management",
      },
      {
        iconName: "assets/icons_svg/data export.svg",
        displayText: "UI.COMMON.DATA_EXPORT",
        url: "/data-export",
      },
      {
        iconName: "assets/icons_svg/data com.svg",
        displayText: "UI.COMMON.DATA_COMMUNICATION",
        url: "/data-communication",
      },
      {
        iconName: "assets/icons_svg/settings.svg",
        displayText: "UI.COMMON.SETTINGS",
        expanded: false,
        hasChild: true,
        subOptions: [
          /*{
            iconName: "assets/icons_svg/ic_sidebar_userInfo.svg",
            displayText: "UI.COMMON.USER_PROFILE_SETTINGS",
            url: "./basic-information",
          },*/
          {
            iconName: "assets/icons_svg/ic_sidebar_threshold.svg",
            displayText: "UI.COMMON.SENSOR_THRESHOLD_MANAGEMENT",
            url: "/sensor-threshold",
          },
          /*{
            iconName: "assets/icons_svg/ic_sidebar_share.svg",
            displayText: "UI.COMMON.SHARING",
            url: "./sharing",
          },
          {
            iconName: "assets/icons_svg/ic_sidebar_share.svg",
            displayText: "UI.COMMON.CONNECT_BY_EMAIL",
            url: "./connect-by-email-address",
          },*/
          {
            iconName: "assets/icons_svg/ic_sidebar_battery.svg",
            displayText: "UI.COMMON.SENSOR_BATTERY_LEVELS",
            url: "/sensor-battery",
          },
          {
            iconName: "assets/icons_svg/ic_sidebar_device.svg",
            displayText: "UI.COMMON.GREENHOUSE_MANAGEMENT",
            url: "/device-management",
          },
          {
            iconName: "assets/icons_svg/ic_sidebar_replace.svg",
            displayText: "UI.COMMON.REPLACE_DEVICE",
            url: "/replace-device",
          }
        ],
      },
    ];
    this.thirdpageList = [
      {
        iconName: "assets/icons_svg/legal notes.svg",
        displayText: "UI.COMMON.LEGAL_INFO",
        expanded: false,
        hasChild: true,
        subOptionsJP: [
          { displayText: "UI.FOOTER_LINK.SITE_MANAGER", num: 1, url: '/license-imprint' },
          { displayText: "UI.FOOTER_LINK.PRIVACY_AND_POLICY", num: 2, url: '/privacy-policy' },
          { displayText: "UI.FOOTER_LINK.TERMS_AND_CONDITIONS", num: 3, url: '/terms-and-services' },
          { displayText: "UI.COMMON.LEGAL_INFORMATION", num: 4, url: '/license-information' }
        ],
        subOptionsKR: [
          { displayText: "UI.FOOTER_LINK.SITE_MANAGER", num: 1, url: '/license-imprint' },
          { displayText: "UI.FOOTER_LINK.PRIVACY_AND_POLICY", num: 2, url: '/privacy-policy' },
          { displayText: "UI.FOOTER_LINK.TERMS_AND_CONDITIONS", num: 3, url: '/terms-and-services' },
          // { displayText: "UI.LICENSE.OPEN_LIBRARIES", num: 5 }
          //{ displayText: "UI.COMMON.FOOTER.KR", num: 0 },
        ],
      },
      // {
      //   iconName: "assets/icons_svg/ic_sidebar_logout.svg",
      //   displayText: "UI.COMMON.LOGOUT",
      //   expanded: false,
      //   hasChild: false,
      // },
      // {
      //   iconName: "assets/icons_svg/bayer-logo.svg",
      //   displayText: "UI.COMMON.BAYER",
      //   expanded: false,
      //   hasChild: false
      // }

    ];

    if (this.platform.is("android") && !this.platform.is("mobileweb")) {
      this.currentPlatform = "android";
      this.menuToggle = true;
    } else if (this.platform.is("ios") && !this.platform.is("mobileweb")) {
      this.menuToggle = true;
      this.currentPlatform = "ios";
    } else if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
      this.menuToggle = true;
      this.currentPlatform = "browser";
    }

    this.utils.eventService.subscribe("greenhouses loaded", (data: any) => {
      if (data) {
        this.menu.enable(true);
        this.pageList = data;
      } else {
        this.menu.enable(false);
      }
    });
    this.checkConnectedUser();
    this.eventService.subscribe('logged-out', () => {
      this.checkConnectedUser();
    });
  }

  ngOnInit() {
    setInterval(() => {
      this.username = localStorage.getItem("username");
      this.region = localStorage.getItem("region");
      this.utils.appConfig.region = localStorage.getItem("region");

      // var label = localStorage.getItem("supportSiteNewIcon");
      // if (label == "true") {
      //   this.supportNewLabel = true;
      // } else {
      //   this.supportNewLabel = false;
      // }
      this.OnWindowResize();

      window.onresize = () => {
        this.OnWindowResize();
      };
    }, 200);
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if(event.url.includes('/switch-account?')) {
          this.isSwitchAccount = true;
        }
      } else if(event instanceof NavigationEnd) {
        if (this.previousScreenName) {  
          this.utils.analyticsService.trackScreen(null); 
        }
        let screenName = this.getPageName(event.urlAfterRedirects);
        if(!screenName.includes('dummy')) {
          console.log("***** current screen ***** ", screenName);
          this.utils.analyticsService.trackScreen(screenName);
          this.previousScreenName = event.urlAfterRedirects;
        }
        if(localStorage.getItem("isUserLoggedIn") === "true" && event.url === '/dashboard' || event.url === '/login') {
          this.checkConnectedUser();
        }
      }
    });
  }

  getPageName(path: string): string {  
    // Remove query string parameters (if any)  
    const pathWithoutQuery = path.split('?')[0];  
    
    // Remove "ScreenLocationChange" suffix (if present)  
    const pathWithoutSuffix = pathWithoutQuery.replace(/;skipLocationChange=true$/i, '');  
    
    // Split path into segments  
    const segments = pathWithoutSuffix.split(/[\/;]/);  
    
    // Get last non-empty segment  
    const lastSegment = segments.filter(Boolean).pop();  
    
    // Convert page name to PascalCase  
    const pascalCaseName = lastSegment  
      .replace(/-([a-z])/g, function(match, letter) {  
        return letter.toUpperCase();  
      })  
      .replace(/^(.)/, function(firstLetter) {  
        return firstLetter.toUpperCase();  
      });  
    
    // Add "Page" suffix to page name  
    return `${pascalCaseName}Page`;  
  }  
  
   
  

  checkConnectedUser() {
    this.username = localStorage.getItem("username");
    this.isConnectedUser = localStorage.getItem("connection") !== null;
    if (this.isConnectedUser) {
      this.secondpageList.splice(3, 1);
      this.secondpageList[3].subOptions.splice(0, 1);
      this.secondpageList[3].subOptions.splice(2, 1)
    }
  }

  getGreenHouses(res) {
    var tempArray = [];
    for (let i = 0; i < res.length; i++) {
      var a = {
        iconName: "assets/icons_svg/ic_sidebar_house.svg",
        displayText: "PAA2 Strawberry",
        id: 0,
        alertCount: 0,
        expanded: false,
        hasChild: true,
        subOptions: [
          {
            id: 1,
            iconName: "assets/icons_svg/ic_detail_overview.svg",
            displayText: "UI.COMMON.GREENHOUSE_SUMMARY",
            url: "/home",
            uniqueName: "GH",
          },
          {
            id: 2,
            iconName: "assets/icons_svg/ic_detail_disease.svg",
            displayText: "UI.COMMON.DISEASE_RISK",
            url: "/home2",
            uniqueName: "DISEASE_RISK",
          },
          {
            id: 3,
            iconName: "assets/icons_svg/ic_detail_temperature.svg",
            displayText: "UI.COMMON.TEMPERATURE",
            url: "/home2",
            uniqueName: "T",
          },
          {
            id: 4,
            iconName: "assets/icons_svg/ic_detail_humidity.svg",
            displayText: "UI.COMMON.HUMIDITY",
            url: "/home4",
            uniqueName: "H",
          },
          {
            id: 5,
            iconName: "assets/icons_svg/ic_detail_co2.svg",
            displayText: "UI.COMMON.CO2",
            url: "/home5",
            uniqueName: "CO",
          },
          {
            id: 6,
            iconName: "assets/icons_svg/ic_detail_solar.svg",
            displayText: "UI.COMMON.SOLAR_RADIATION",
            url: "/home6",
            uniqueName: "SR",
          },
          {
            id: 7,
            iconName: "assets/icons_svg/ic_detail_dewPoint.svg",
            displayText: "UI.COMMON.DEW_POINT",
            url: "/home7",
            uniqueName: "DP",
          },
          {
            id: 8,
            iconName: "assets/icons_svg/ic_detail_hd.svg",
            displayText: "UI.COMMON.HUMIDITY_DEFICIT",
            url: "/home7",
            uniqueName: "HD",
          },
          {
            id: 9,
            iconName: "assets/icons_svg/ic_detail_temperature.svg",
            displayText: "UI.ACCUMULATED_TEMPERATURE.HEADER",
            url: "/home7",
            uniqueName: "ACCUMULATED_TEMP",
          },
        ],
      };
      a.displayText = res[i].name;
      a.id = res[i].id;
      a.alertCount = res[i].alertsCount;
      tempArray.push(a);
    }
    return tempArray;
  }

  OnWindowResize() {
    if (window.innerWidth > 1200) {
      this.screenDimension = true;
    } else {
      this.screenDimension = false;
    }
  }

  displaySensor(c) {
    c.expanded = !c.expanded;
  }

  displaySettings(c) {
    c.expanded = !c.expanded;
  }

  displayLegalInfo(c) {
    c.expanded = !c.expanded;
  }

  greenHouseSummary(c) {
    this.router.navigateByUrl('/ghdummy-route', {skipLocationChange: true}).then(() => {
      const params: NavigationExtras = {
        queryParams: {
          greenHouseId: c.id,
          greenHouseName: c.displayText,
          updatedOn: "",
        }
      }
      this.navCtrl.navigateForward(["/greenhouse-summary"], params);
    });
  }

  displaySensor2(c, i) {
    this.gh = c;
    this.showOption = true;
    this.optionTopMargin = 205 + i * 45;
    this.optionTopMargin = null;

    window.addEventListener("mouseover", (e) => {
      if (e.clientX < 200) {
        console.log(e.clientY);
        this.optionTopMargin = e.clientY;
      }
    });
  }

  displaySettings2(c) {
    this.settingList = c;
    this.settingSubOpt = !this.settingSubOpt;
  }

  onMouseleave(value) {
    this.optionTopMargin = null;
    if (value === "greenhouse") {
      this.showOption = false;
      this.howerEffect = false;
    } else if (value === "setting") {
      this.settingSubOpt = false;
      this.settingHowerEffect = false;
    }
  }

  onMouseEnter(value) {
    if (value === "greenhouse") {
      this.howerEffect = true;
      this.showOption = true;
    } else if (value === "setting") {
      this.settingHowerEffect = true;
      this.settingSubOpt = true;
    }
  }

  howerEffectSettings(value) {
    if (value === "greenhouse") {
      if (this.howerEffect) {
        this.showOption = true;
      } else {
        this.showOption = false;
      }
    } else if (value === "setting") {
      if (this.settingHowerEffect) {
        this.settingSubOpt = true;
      } else {
        this.settingSubOpt = false;
      }
    }

    window.addEventListener("mouseover", null);
  }

  initializeApp() {
    this.utils
      .httpGetApi(this.utils.appConfig.environmentFileURL)
      .subscribe((envVars) => {
        this.utils.loadEnvironmentVars(envVars);
        if(!this.isSwitchAccount) {
          if(localStorage.getItem("isUserLoggedIn") == "true") {
            this.utils.getGreenHouseData().then((convertedResponse:any) => {
              if (convertedResponse.length !== 0) {
                let data = this.getGreenHouses(convertedResponse);
                if (data) {
                  this.menu.enable(true);
                  this.pageList = data;
                  let greenHouses = convertedResponse;
                  let aletCount = 0;
                  if(greenHouses.length > 0){
                    greenHouses.forEach(GH => aletCount +=GH.alertsCount)
                  }
                  this.alertCount = aletCount;
                  console.log("##alertCount",this.alertCount)
                  this.content.scrollToPoint(0, this.scrollPosition);
                } else {
                  this.menu.enable(false);
                }
              }
            }).catch(err => {
            });
            
            if(this.isConnectedUser) {
              this.navCtrl.navigateRoot("/dashboard");
            }
            // this.checkLoginValidation();
          } else {
            this.navCtrl.navigateRoot("/login");
          }
        }
      });

    if (this.platform.is("android")) {
      this.androidPermissions.requestPermissions([
        this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
        this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
      ]);
    }
    this.username = localStorage.getItem("username");
    this.region = localStorage.getItem("region");
    this.utils.appConfig.region = localStorage.getItem("region");
  }

  setupDeepLinks() {
    this.deeplinks.routeWithNavController(this.navChild, {
      '/switch-account': 'switch-account',
    }).subscribe(
      match => {
        this.isSwitchAccount = true;
        // Run the navigation in the Angular zone
        this.zone.run(() => {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              code: match.$args['code'],
              userId: match.$args['userId']
            }
          };
          // alert("************ matched ***********" + JSON.stringify(navigationExtras));
          this.router.navigate(['switch-account'], navigationExtras);
        });
      },
      nomatch => {
        // alert(" ************ " + JSON.stringify(nomatch));
        // nomatch.$link - the full link data
        console.error("Got a deeplink that didn't match", JSON.stringify(nomatch));
      }
    );
  }

  async checkLoginValidation() {
    // this.utils.showTimeOutLoading(2000);
    await this.utils
      .checkTokenValidity()
      .then((res) => {
        console.log("##res recieved in app", res);
        if (res) {
          this.menu.enable(true);
          this.navCtrl.navigateRoot("/dashboard");
        } else {
          this.menu.enable(false);
          this.navCtrl.navigateRoot("/login");
        }
      })
      .catch((err) => {
        console.log("##err recieved in app", err);
        this.menu.enable(false);
        this.navCtrl.navigateRoot("/login");
      });
  }

  logout() {
    this.utils.logout();
  }

  gotoPages(e) {
    this.settingSubOpt = false;
    this.router.navigate([e.url]);
  }

  async legalnotes(num) {
    if (num == 1) {
      // if (window.hasOwnProperty("cordova")) {
      //   if (localStorage.getItem("region") === "KR") {
      //     this.iab.create(this.utils.appConfig.support_license_URL+"/#/KR/siteManager");
      //   } else {
      //     this.iab.create(this.utils.appConfig.support_license_URL+"/#/JP/siteManager");
      //   }
      // } else {
      //   if (localStorage.getItem("region") === "KR") {
      //     window.open(this.utils.appConfig.support_license_URL+"/#/KR/siteManager");
      //   } else {
      //     window.open(this.utils.appConfig.support_license_URL+"/#/JP/siteManager");
      //   }
      // }
      this.router.navigate(["./license-imprint", { skipLocationChange: true }]);
    } else if (num == 2) {
      if (window.hasOwnProperty("cordova")) {
        if (localStorage.getItem("region") === "KR") {
          this.iab.create(
            "https://www.cropscience.bayer.co.kr/Privacy-statement/Privacy-statement", "_system"
          );
        } else {
          this.iab.create("https://cropscience.bayer.jp/ja/home/policy/", "_system");
        }

        //Open new tab  https://www.cropscience.bayer.co.kr/Privacy-statement/Privacy-statement​
      } else {
        if (localStorage.getItem("region") === "KR") {
          window.open(
            "https://www.cropscience.bayer.co.kr/Privacy-statement/Privacy-statement"
          );
        } else {
          window.open("https://cropscience.bayer.jp/ja/home/policy/");
        }
      }
    } else if (num == 3) {
      if (window.hasOwnProperty("cordova")) {
        if (localStorage.getItem("region") === "KR") {
          this.iab.create(this.utils.appConfig.support_license_URL + "/#/KR/termsConditions", "_system");
        } else {
          this.iab.create(
            "https://cropscience.bayer.jp/ja/home/plantect/forms/conditions.php", "_system"
          );
        }
      } else {
        if (localStorage.getItem("region") === "KR") {
          window.open(this.utils.appConfig.support_license_URL + "/#/KR/termsConditions");
        } else {
          window.open(
            "https://cropscience.bayer.jp/ja/home/plantect/forms/conditions.php"
          );
        }
      }
    } else if (num == 4) {
      this.router.navigateByUrl("/license-information");
    } else if (num == 5) {
      this.router.navigateByUrl("/open-libraries");
    }
  }

  goToAnalytics() {
    this.router.navigateByUrl("/analytics");
  }

  goToDashboard() {
    this.router.navigateByUrl("/dashboard");
  }
  goToManagement() {
    this.router.navigateByUrl("/management");
  }
  goToGuide() {
    this.router.navigateByUrl("/guide");
  }
  goToUsers(){
    this.router.navigateByUrl("/users");
  }
  goToLegalnotes(){
    this.router.navigateByUrl("/web-legalnotes");
  }
  goToTemperature() { }
  goToLogout(){
    this.utils.logout()
  }
 async goToSupport(){
    const supportURL:any =  await this.utils.getSupportURL()
    if (window.hasOwnProperty("cordova")) {
      this.iab.create(supportURL, "_system");
    } else {
      window.open(supportURL);
    }
  }
  goToData(data: any, data2?: any) {
    this.showOption = !this.showOption;
    if (data.id == 1) {
      this.navCtrl.navigateForward(["greenhouse-summary"], {
        queryParams: {
          greenHouseId: data2.id,
          greenHouseName: data2.displayText,
          updatedOn: "",
        },
      });
    } else if (data.id == 2) {
      this.navCtrl.navigateForward(["disease-risk"], {
        queryParams: {
          greenHouseId: data2.id,
          greenHouseName: data2.displayText,
        },
      });
    } else if (data.id == 9) {
      this.navCtrl.navigateForward(["accumulated-temp"], {
        queryParams: {
          greenHouseId: data2.id,
          greenHouseName: data2.displayText,
        },
      });
    } else {
      this.navCtrl.navigateForward(["temperature"], {
        queryParams: {
          greenHouseId: data2.id,
          greenHouseName: data2.displayText,
          pageType: data.uniqueName,
        },
      });
    }
  }

  setPlatformListener() {
    this.platform.pause.subscribe(() => {
      // background
      console.log("In Background");
      localStorage.setItem('minDateTime', new Date().toString());
    });

    this.platform.resume.subscribe(() => {
      // foreground
      console.log("In Foreground");
      let currentDateTime = new Date();
      let minDateTime = localStorage.getItem('minDateTime');
      let interval = new Date(currentDateTime).valueOf() - new Date(minDateTime).valueOf();
      if (interval / (60 * 1000) >= 15) {
        window.location.reload();
      }
    });
  }
  isDesktop() {
    if (window.innerWidth > 1200) {
      return true;
    }
    return false;
  }
  isLoggedin() {
    if(this.router.url.includes("/switch-account")){
      return false
    }else if(localStorage.getItem("isUserLoggedIn") === "true") {
      return true;
    }return false;
  }
  ngAfterViewInit() {
    // this.content.addScrollListener(this.menuScrolled);
  }
  menuScrolled() {
    this.content.getScrollElement().then(el => {
      console.log("*scrollp*",el.scrollTop);
      this.scrollPosition = el.scrollTop;
    });
  }
  ngOnDestroy() {
    if(this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.eventService.destroy('logged-out');
  }
}
