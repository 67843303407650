import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
})
export class InfoPopoverComponent implements OnInit {
  lang: string;
  msg:any;
  constructor(private navParams: NavParams) {
    this.lang = localStorage.getItem('setLanguage');
    this.msg = navParams.get('message');
   }

  ngOnInit() {}

}
