import { Injectable } from "@angular/core";
import { Network } from "@ionic-native/network/ngx";
import { Platform } from "@ionic/angular";
import { AppConfig } from "./app-config";

@Injectable()
export class NetworkStatusService {
  onDevice: boolean;
  constructor(
    private platform: Platform,
    private appConfig: AppConfig,
    private network: Network
  ) {
    this.onDevice = this.platform.is("cordova");
    console.log(" this.network.type in network constructor", this.network.type);
  }

  //   if (this.onDevice && this.network.type) {
  //     console.log(this.network.type);
  //     return (this.network.type !== this.appConfig.none && this.network.type !== 'unknown');
  // } else {
  //     return navigator.onLine;
  // }
  isOnline(): boolean {
    if (this.network.type) {
      return (
        this.network.type != this.appConfig.none 
      );
    } else {
      return navigator.onLine;
    }
  }
}
