import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, NavParams, PopoverController } from '@ionic/angular';
import { Utils } from 'src/utils/utils';
import { TranslationService } from 'src/utils/translation-service';
import { HttpService } from 'src/utils/http-service';
@Component({
  selector: 'app-user-popover-page',
  templateUrl: './user-popover-page.page.html',
  styleUrls: ['./user-popover-page.page.scss'],
})
export class UserPopoverPagePage implements OnInit {
  connected_users=[];
  showUser = true;
  username:any;
  isConnectedUser:any;
  constructor(private popoverController: PopoverController,
    private navParams: NavParams,
    private router: Router,
    private utils: Utils,
    private translate: TranslationService,
    public navCtrl: NavController,
    public httpService: HttpService) {
   this.username = localStorage.getItem("rootusername");
   this.isConnectedUser = localStorage.getItem("connection") != null;
  }
  async ionViewDidEnter(){
    var users:any = await this.utils.loadConnectedUsers(); // connectedUsers.connectionList;
    users = users.connectionList;
    console.log("###users ---",users);
    if(localStorage.getItem("connection") !== null){
      this.connected_users = users.filter(user => {
        return user.userId == JSON.parse(localStorage.getItem("connection")).userId && user.connectUserId == JSON.parse(localStorage.getItem("connection")).connectUserId
      });
     }else{
      this.connected_users = users;
     }
  }

  ngOnInit() {
  }
  dismissModal() {
    this.popoverController.dismiss();
  }
  handleNavigation(key){
    this.popoverController.dismiss()
    switch (key) {
      case 0:
        this.router.navigate(["./basic-information"]);
        break;
      case 1:
        localStorage.removeItem("connection");
        this.utils.logout()
        break;
      case 2:
        this.router.navigate(["./sharing"]);
        break;
      case 3:
        this.router.navigate(["./connect-by-email-address"]);
        break;   
      default:
        break;
    }
  }
  clickConnectUser(user){
    if(user == 1){
      localStorage.removeItem("connection");
      localStorage.setItem("isConnectedUser","0");
      localStorage.setItem("username",localStorage.getItem("rootusername"));
    }else{
      console.log("####user",user);
      localStorage.setItem("connection",JSON.stringify(user));
      localStorage.setItem("isConnectedUser","1");
      localStorage.setItem("username",user.connectedUserRelative);
    }
    this.popoverController.dismiss();
    setTimeout(() => {
      window.location.reload();
    },1000)
    
  }
}
