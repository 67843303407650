import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot-user',
    loadChildren: () => import('./pages/forgot-user/forgot-user.module').then( m => m.ForgotUserPageModule)
  },
  {
    path: 'register-unregister-user',
    loadChildren: () => import('./pages/register-unregister-user/register-unregister-user.module').then( m => m.RegisterUnregisterUserPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./pages/analytics/analytics.module').then( m => m.AnalyticsPageModule)
  },
  {
    path: 'basic-information',
    loadChildren: () => import('./pages/basic-information/basic-information.module').then( m => m.BasicInformationPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'email-alert-setting',
    loadChildren: () => import('./pages/email-alert-setting/email-alert-setting.module').then( m => m.EmailAlertSettingPageModule)
  },
  {
    path: 'sharing',
    loadChildren: () => import('./pages/sharing/sharing.module').then(m => m.SharingPageModule)
  },{
    path: 'device-management',
    loadChildren: () => import('./pages/device-management/device-management.module').then( m => m.DeviceManagementPageModule)
  },
  {
    path: 'edit-device-changes',
    loadChildren: () => import('./pages/edit-device-changes/edit-device-changes.module').then( m => m.EditDeviceChangesPageModule)
  },

  {
    path: 'add-device-details',
    loadChildren: () => import('./pages/add-device-details/add-device-details.module').then( m => m.AddDeviceDetailsPageModule)
  },
  {
    path: 'add-greenhouse',
    loadChildren: () => import('./pages/add-greenhouse/add-greenhouse.module').then( m => m.AddGreenhousePageModule)
  },
  {
    path: 'crop-season-management',
    loadChildren: () => import('./pages/crop-season-management/crop-season-management.module').then( m => m.CropSeasonManagementPageModule)
  },
  {
    path: 'sensor-battery',
    loadChildren: () => import('./pages/sensor-battery/sensor-battery.module').then( m => m.SensorBatteryPageModule)
  },
  {
    path: 'replace-device',
    loadChildren: () => import('./pages/replace-device/replace-device.module').then( m => m.ReplaceDevicePageModule)
  },
  {
    path: 'license-information',
    loadChildren: () => import('./pages/license-information/license-information.module').then( m => m.LicenseInformationPageModule)
  },
  {
    path: 'data-export',
    loadChildren: () => import('./pages/data-export/data-export.module').then( m => m.DataExportPageModule)
  },
  {
    path: 'sensor-threshold',
    loadChildren: () => import('./pages/sensor-threshold/sensor-threshold.module').then( m => m.SensorThresholdPageModule)
  },
  {
    path: 'sensor-threshold-settings',
    loadChildren: () => import('./pages/sensor-threshold-settings/sensor-threshold-settings.module').then( m => m.SensorThresholdSettingsPageModule)
  },
  {
    path: 'greenhouse-summary',
    loadChildren: () => import('./pages/greenhouse-summary/greenhouse-summary.module').then( m => m.GreenhouseSummaryPageModule)
  },
 
  {
    path: 'edit-crop-season',
    loadChildren: () => import('./pages/edit-crop-season/edit-crop-season.module').then( m => m.EditCropSeasonPageModule)
  },

  {
    path: 'analytic-settings',
    loadChildren: () => import('./component/analytic-settings/analytic-settings.module').then( m => m.AnalyticSettingsPageModule)
  },
  {
    path: 'calibrate',
    loadChildren: () => import('./pages/calibrate/calibrate.module').then( m => m.CalibratePageModule)
  },

  {
    path: 'scanner-model',
    loadChildren: () => import('./component/scanner-model/scanner-model.page.module').then( m => m.ScannerModelPageModule)
  },
  {
    path: 'helping',
    loadChildren: () => import('./pages/helping/helping.module').then( m => m.HelpingPageModule)
  },
  {
    path: 'chemical-spray-record',
    loadChildren: () => import('./pages/chemical-spray-record/chemical-spray-record.module').then( m => m.ChemicalSprayRecordPageModule)
  },
  {
    path: 'disease-record-screen',
    loadChildren: () => import('./pages/disease-record-screen/disease-record-screen.module').then( m => m.DiseaseRecordScreenPageModule)
  },

  {
    path: 'opensource-libraries',
    loadChildren: () => import('./pages/opensource-libraries/opensource-libraries.module').then( m => m.OpensourceLibrariesPageModule)
  },
  {
    path: 'ghdummy-route',
    loadChildren: () => import('./pages/ghdummy-route/ghdummy-route.module').then( m => m.GHDummyRoutePageModule)
  },
  {
    path: 'license-imprint',
    loadChildren: () => import('./pages/license-imprint/license-imprint.module').then( m => m.LicenseImprintPageModule)
  },
  {
    path: 'open-libraries',
    loadChildren: () => import('./pages/open-libraries/open-libraries.module').then( m => m.OpenLibrariesPageModule)
  },
  {
    path: 'data-communication',
    loadChildren: () => import('./pages/data-communication/data-communication.module').then( m => m.DataCommunicationPageModule)
  },
  {
    path: 'connect-by-email-address',
    loadChildren: () => import('./pages/connect-by-email-address/connect-by-email-address.module').then( m => m.ConnectByEmailAddressPageModule)
  },
  {
    path: 'user-popover-page',
    loadChildren: () => import('./pages/user-popover-page/user-popover-page.module').then( m => m.UserPopoverPagePageModule)
  },
  {
    path: 'change-email-adress',
    loadChildren: () => import('./pages//change-email-adress/change-email-adress.module').then( m => m.ChangeEmailAdressPageModule)
  },
  {
    path: 'add-device-prompt',
    loadChildren: () => import('./pages/add-device-prompt/add-device-prompt.module').then( m => m.AddDevicePromptPageModule)
  },
  {
    path: 'management',
    loadChildren: () => import('./pages/management/management.module').then( m => m.ManagementPageModule)
  },
  {
    path: 'guide',
    loadChildren: () => import('./pages/guide/guide.module').then( m => m.GuidePageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'web-legalnotes',
    loadChildren: () => import('./pages/web-legalnotes/web-legalnotes.module').then( m => m.WebLegalnotesPageModule)
  },
  {
    path: 'switch-account',
    loadChildren: () => import('./pages/switch-account/switch-account.module').then( m => m.SwitchAccountPageModule)
  }



];



@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
