import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class EventService {
    // private response = new BehaviorSubject<any>('');
    //  private unexpctedresponse = new Subject();
    //  private expectedresponse = new Subject();
    // currentUnexpectedMessage = this.unexpctedresponse.asObservable();
    // currentExpectedMessage = this.expectedresponse.asObservable();
    private channels: { [key: string]: Subject<any>; } = {};
    constructor() {

    }
    subscribe(topic: string, observer: (_: any) => void): Subscription {
        if (!this.channels[topic]) {
            this.channels[topic] = new Subject<any>();
        }

        return this.channels[topic].subscribe(observer);
    }
    publish(topic: string, data: any): void {
        const subject = this.channels[topic];
        if (!subject) {
            // Or you can create a new subject for future subscribers
            return;
        }

        subject.next(data);
    }
    destroy(topic: string): null {
        const subject = this.channels[topic];
        if (!subject) {
            return;
        }

        subject.complete();
        delete this.channels[topic];
    }
    // eventPublish(num) {
    //     this.unexpctedresponse.next(num);
    // }
    // eventPublishExpected(num){
    //     this.expectedresponse.next(num);
    // }
    // eventUnsubscribe(){
    //     this.unexpctedresponse.unsubscribe();
    // }
}
