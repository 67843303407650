import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-temperature-helper',
  templateUrl: './temperature-helper.component.html',
  styleUrls: ['./temperature-helper.component.scss'],
})
export class TemperatureHelperComponent implements OnInit {

  lang: string;
  redirectType:string;
  constructor(private navParams: NavParams) { 
    this.lang = localStorage.getItem('setLanguage');
    this.redirectType = navParams.get('TYPE_KEY');
  }

  ngOnInit() {}

}
