import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent implements OnInit,OnDestroy {
  username: string;
  @Input()  backBtn: Boolean;
  isUserLoggedIn:any;
  constructor(
    public utils: Utils,
    public router: Router,
    public navCtrl: NavController,
  ) { 

  }
  ngOnDestroy(){
    this.utils.eventService.destroy('userNameUpdate');
  }
  ngOnInit() {
    this.username = localStorage.getItem("username");
    this.utils.eventService.subscribe('userNameUpdate',() => {
      this.username = localStorage.getItem("username");
    })
    this.isUserLoggedIn = localStorage.getItem("isUserLoggedIn") == "true";
  }
  navigateBack() {
    if(this.router.url == '/add-device-details?title=gateway'){
      this.router.navigateByUrl("/device-management");
    }else if(this.router.url == '/device-management'){
      this.router.navigateByUrl("/management");
    }else if(this.router.url.includes('/connect-by-email-address')){
      this.router.navigateByUrl("/dashboard");
    }else{
      this.navCtrl.back();
    }
  }
}
