import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';

export enum AnalyticsEvents {
  "LOGIN" = "login",
  "LOGOUT" = "logout",
  "REGISTER" = "sign_up",
  "DELETE_ACCOUNT" = "delete_account",
  "SWITCH_ACCOUNT" = "switch_account",

  "ANALYTICS_SETTINGS_UPDATED" = "analytics_settings_updated",

  "GH_TEMP" = "gh_temp",
  "GH_RELATIVE_HUMIDITY" = "gh_relative_humidity",
  "GH_HUMIDITY_DEFICIT" = "gh_humidity_deficit",
  "GH_CO2" = "gh_co2",
  "GH_SOLAR_RADIATION" = "gh_solar_radiation",
  "GH_DEW_POINT_TEMP" = "gh_dew_point_temp",
  "GH_ACCUMULATED_TEMP" = "gh_accumulated_temp",
  "GH_DISEASE_RISK" = "gh_disease_risk",

  "GH_ACCUM_TEMP_STARTED" = "gh_accum_temp_started",
  "GH_ACCUM_TEMP_RESET" = "gh_accum_temp_reset",

  "GH_DISEASE_RISK_ADDED" = "gh_disease_risk_added",
  "GH_DISEASE_RISK_UPDATED" = "gh_disease_risk_updated",
  "GH_DISEASE_RISK_DELETED" = "gh_disease_risk_deleted",

  "GH_CHEMICAL_SPRAY_ADDED" = "gh_chemical_spray_added",
  "GH_CHEMICAL_SPRAY_UPDATED" = "gh_chemical_spray_updated",
  "GH_CHEMICAL_SPRAY_DELETED" = "gh_chemical_spray_deleted",

  "NOTIFICATION_ALLOWED" = "notification_allowed",
  "NOTIFICATION_DENIED" = "notification_denied",
  "NOTIFICATION_SENSOR" = "notification_sensor",
  "NOTIFICATION_DISEASE" = "notification_disease",
  "NOTIFICATION_PROMOTION" = "notification_promotion",

  "GREENHOUSE_ADDED" = "greenhouse_added",
  "GREENHOUSE_UPDATED" = "greenhouse_updated",
  "GREENHOUSE_DELETED" = "greenhouse_deleted",

  "SENSOR_ADDED" = "sensor_added",
  "SENSOR_REPLACED" = "sensor_replaced",
  "SENSOR_DELETED" = "sensor_deleted",

  "SENSOR_THRESHOLD_UPDATED" = "sensor_threshold_updated",

  "GATEWAY_ADDED" = "gateway_added",
  "GATEWAY_UPDATED" = "gateway_updated",
  "GATEWAY_DELETED" = "gateway_deleted",

  "CALIBRATE" = "calibrate",

  "DATA_EXPORT_CSV" = "data_export_csv",
  "DATA_EXPORT_SEASON" = "data_export_season",

  "ALERT_INTERVAL_UPDATE" = "alert_interval_update",

  "CROP_SEASON_ADDED" = "crop_season_added",
  "CROP_SEASON_UPDATED" = "crop_season_updated",
  "CROP_SEASON_CLOSED" = "crop_season_closed",
  "CROP_SEASON_REOPENED" = "crop_season_reopened",

  "ALERT_LINE_OPTED" = "alert_line_opted",
  "ALERT_EMAIL_OPTED" = "alert_email_opted",
  "ALERT_LINE_EMAIL_OPTED" = "alert_line_email_opted",

  "INVITE_BY_EMAIL" = "invite_by_email",
  "CANCEL_INVITE" = "cancel_invite",
  "INVITATION_ACCETED" = "invitation_accepted",
  "INVITATION_REJECTED" = "invitation_rejected",
  "CLOSE_CONNECTION" = "close_connection",

  "DATA_COMMUNICATION_SUSPEND" = "data_communication_suspend",
  "DATA_COMMUNICATION_REOPEN" = "data_communication_reopen",

  "SHARING" = "sharing",

  "DISPLAY_NAME_CHANGE" = "display_name_change",
  "EMAIL_CHANGE" = "email_change"
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private firebaseAnalytics: FirebaseAnalytics,
    private platform: Platform
  ) { }

  // Tracks a custom event in Firebase Analytics
  logEvent(name: string, properties: object) {
    this.platform.ready().then(() => {
      if(this.platform.is('cordova')) {
        this.firebaseAnalytics.logEvent(name, properties).then(res => {
          console.log("logEvent success", res);
        }).catch(err => {
          console.log("logEvent error", err);
        });
      }
    })
  }

  setUserProperty(key: string, value: string) {
    this.platform.ready().then(() => {
      if(this.platform.is('cordova')) {
        this.firebaseAnalytics.setUserProperty(key, value);
      }
    });
  }

  // Tracks an 'screen_view' event in Firebase Analytics
  trackScreen(name: string) {
    this.platform.ready().then(() => {
      if(this.platform.is('cordova')) {
        this.firebaseAnalytics.setCurrentScreen(name).then(res => {
          console.log("trackScreen success", res);
        }).catch(err => {
          console.log("trackScreen error", err);
        });
      }
    });
  } 

  setUserId(uid: string) {
    this.platform.ready().then(() => {
      if(this.platform.is('cordova')) {
        this.firebaseAnalytics.setUserId(uid);
      }
    });
  }
}