import { Injectable, OnDestroy, resolveForwardRef } from "@angular/core";
import { TranslationService } from "./translation-service";
import { AlertService } from "./alert-service";
import { ToastService } from "./toast-service";
import { Observable, Subscription } from "rxjs";
import { HttpService } from "./http-service";
import { LoadingService } from "./loading.service";
import { AppConfig } from "./app-config";
import { KeychainService } from "./keychain-service";
import { NetworkStatusService } from "./network-status.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as momentT from 'moment-timezone';
import * as moment from 'moment';
import { LoadingController, Platform, ToastController, PopoverController, NavController } from "@ionic/angular";
import { NavigationEnd, Router, NavigationExtras } from "@angular/router";
import { EventService } from '../utils/event-service';
import { AnalyticsEvents, AnalyticsService } from './analytics-service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { retry } from "rxjs/operators";
import { UserPopoverPagePage } from "src/app/pages/user-popover-page/user-popover-page.page";
import * as momenttz from 'moment-timezone';

@Injectable({
  providedIn: "root",
})
export class Utils implements OnDestroy {

  refreshTokenExpired: boolean = false;

  languageList: Array<any> = [
    {
      name: "日本語",
      languageCode: "ja",
    },
    {
      name: "English",
      languageCode: "en",
    },
    {
      name: "한국어",
      languageCode: "ko",
    },
  ];
  analyticsEvent = [
    {
      userId: "",
      messageId: "",
      originalTimestamp: "",
      context: {
        app: {
          name: "",
          namespace: "",
        },
        timezone: "",
        userAgent: "",
        locale: "",
      },
      event: "",
      properties: {
        screen_name: "",
        namespace: "",
        screen: "",
        taxonomy_version: "v1",
      }
    }
  ];
  currentPlatform: string;
  currentLanguage: any;
  confirmationText: any;
  okText: any;

  // we are using gap time to refersh the token before its expired.

  gapTimeToRefreshToken = 60000; //getting token before 1 min of expiry
  expiry: any;
  refreshIntervalId: any;
  firebaseToken: any;
  isUserLoggedIn = false;
  showIcon = false;
  labelInterval: any;
  isLoading = false;
  comparedGraph: boolean = false;
  currentLoading = null;
  routerSubscription: any;
  user_email: any;
  connectedUsers: any = [];
  userPopover: any;
  lineAuthentication: boolean = false;
  alertCount: number=0;
  constructor(
    private translate: TranslationService,
    private alertService: AlertService,
    private toastService: ToastService,
    private httpService: HttpService,
    private loadingService: LoadingService,
    public appConfig: AppConfig,
    private keychainservice: KeychainService,
    public networkService: NetworkStatusService,
    private platform: Platform,
    private router: Router,
    private toastCtrl: ToastController,
    public eventService: EventService,
    public httpClient: HttpClient,
    public loadingController: LoadingController,
    public analyticsService: AnalyticsService,
    private iab: InAppBrowser,
    private popoverController: PopoverController,
    public navCtrl: NavController
  ) {
    if (this.platform.is('android')) {
      this.currentPlatform = 'android';
    }
    else if (this.platform.is('ios')) {
      this.currentPlatform = 'ios';
    } else if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.currentPlatform = 'browser'
    }
    this.confirmationText = this.translateText("UI.COMMON.CONFIRM");
    this.okText = this.translateText('UI.COMMON.OK_BUTTON');
    this.routerSubscription = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        console.log("************* route changed *******");
        await this.dismissAllLoaders();
      }
    });
    this.platform.pause.subscribe(() => {
      this.userPopover.dismiss();
    });
  }

  async dismissAllLoaders() {
    let topLoader = await this.loadingController.getTop();
    console.log("*****dismissing all loaders");
    while (topLoader) {
      if (!(await topLoader.dismiss())) {

        throw new Error('Could not dismiss the topmost loader. Aborting...');

      }
      topLoader = await this.loadingController.getTop();
    }
  }
  async navigateToAccount(ev: any) {
    let userPopover = await this.popoverController.create({
      component: UserPopoverPagePage,
      event: ev,
      translucent: true
    });
    return userPopover.present();
  }

  async presentLoading(loadingId?) {
    /*console.log("presentLoading", loadingId);
    //this.loadingService.present(loadingId);
    let loadingMessage = this.translateText("UI.COMMON.PALNTECT.PLEASE_WAIT");
    return await this.loadingController.create({
      message: loadingMessage,
      spinner: 'crescent',
      mode: 'ios'
    }).then(a => {
      a.present();
    });*/
    if (this.currentLoading != null) {
      this.currentLoading.dismiss();
    }
    await this.dismissAllLoaders();
    let loadingMessage = this.translateText("UI.COMMON.PALNTECT.PLEASE_WAIT");
    this.currentLoading = await this.loadingController.create({
      message: loadingMessage,
      spinner: 'crescent',
      mode: 'ios'
    });
    return await this.currentLoading.present();
  }

  async dismissLoading(loadingId?) {
    console.log("##dismissLoading", loadingId);
    if (this.currentLoading != null) {
      await this.loadingController.dismiss();
      this.currentLoading = null;
    }
    return;
  }

  loadEnvironmentVars(envVars) {
    this.appConfig.loadEnvironmentVars(envVars);
  }
  checkUpdateToken(expiry?: any) {

    if (expiry) {
      this.expiry = expiry;
      console.log("this.expiry", this.expiry);
    }

    if (expiry == null) {
      console.log("expiry cleared", expiry);
      console.log("refreshIntervalId", this.refreshIntervalId);
      clearInterval(this.refreshIntervalId);
    } else {
      let self = this;

      console.log('myFunction');

      this.refreshIntervalId =
        setInterval(function () {
          let currentDatetime = new Date();
          console.log(' myFunction setting new token', currentDatetime);
          self.checkTokenValidity()

            .then((res) => {

              console.log(" myFunction token valid", currentDatetime);

              self.setAuthorization(true)

                .then((re) => {

                  console.log(' myFunction access token set', currentDatetime);

                })

            })

        }, (expiry * 1000) - self.gapTimeToRefreshToken);
    }

  }
  /******************* Loading Service    ***************/

  /******************* TRANSLATION SERVICE *********************/
  initTranslate() {
    this.translate.initTransalation();
  }

  /* loaderTranslatorWithoutDuration(message) {
    this.translate.loaderTranslatorWithoutDuration(message);
  } */

  showAlert(title, msg, mode, buttons, subtitle?) {
    this.alertService.showAlert(title, msg, mode, buttons, subtitle);
  }

  alertMessageBeforeDelete(type, message, action1, action2) {
    return this.alertService.alertMessageBeforeDelete(type, message, action1, action2);
  }

  alertTranslator(header, message, buttons, params?, subHeader?, cssClass?) {
    this.translate.alertTranslator(
      header,
      message,
      buttons,
      params,
      subHeader,
      cssClass
    );
  }

  confirmationAlert(type, message, action1, action2): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.confirmationAlert(type, message, action1, action2).then(res => {
        resolve(res);
      }).catch(err => {
        resolve(err);
      });
    });
  }
  confirmationAlertWithYesorNo(type, message, action1, action2): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.confirmationAlertWithYesorNo(type, message, action1, action2).then(res => {
        resolve(res);
      }).catch(err => {
        resolve(err);
      });
    });
  }


  confirmationAlertOkOnly(header, message, buttonName): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.confirmationAlertWithOkOnly(header, message, buttonName).then(res => {
        resolve(res);
      }).catch(err => {
        resolve(err);
      });
    });
  }

  createCustomAlert(type, message, action1, action2): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.createCustomAlert(type, message, action1, action2).then(res => {
        resolve(res);
      }).catch(err => {
        resolve(err);
      });
    });
  }

  customAlertWithYesOption(type, message, action1, action2): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.customAlertWithYesOption(type, message, action1, action2).then(res => {
        resolve(res);
      }).catch(err => {
        resolve(err);
      });
    });
  }
  replaceAlertMessage(type, message, action1, action2): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.replaceAlertMessage(type, message, action1, action2).then(res => {
        resolve(res);
      }).catch(err => {
        resolve(err);
      });
    });
  }
  alertWithNoAction(type, message, action): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.alertWithNoAction(type, message, action).then(res => {
        resolve(res);
      }).catch(err => {
        resolve(err);
      });;
    });
  }
  /* checkboxAlertTranslate(msg, type, value, flag, btn1, btn2, className, onConfirm) {
    this.translate.CheckboxAlertTranslator(msg, type, value, flag, btn1, btn2, className, onConfirm);
  }

  radioCheckListAlertTranslate(title, listObjects, type, btn1, btn2, className, onConfirm) {
    this.translate.radioCheckListAlertTranslator(title, listObjects, type, btn1, btn2, className, onConfirm);
  } */

  showToastMessage(message) {
    this.toastService.showToastWithoutAction(message);
  }

  /* destroyToastMessage(): boolean {
    return this.toastService.destroyToast();
  } */

  ToastMessageTranslate(message, duration, position, className) {
    this.translate.ToastMessageTranslate(
      message,
      duration,
      position,
      className
    );
  }

  changeLangOfApp(lang) {
    this.translate.changeCurrentBrowserLang(lang);
  }

  getTranslatedMessage(message, params?): any {
    return this.translate.Dotranslate(message, params);
  }

  translateText(text: string) {
    return this.translate.translateText(text);
  }

  /******************* HTTP SERVICE *************************/
  setAuthorization(callDelay?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("this.currentPlatform, callDelay", this.currentPlatform, callDelay);
      this.keychainservice.retrieveSensitiveData('accessToken', this.currentPlatform, callDelay).then(async accessToken => {
        console.log("Access Token from keychain", accessToken);
        await this.httpService.setAuthorization(accessToken);
        resolve('done');
      }).catch(err => {
        console.log("error in catch", JSON.stringify(err));
        reject();
      })
    })
  }

  // postDetailsForDeviceKeyCloak(url: string, data: any): Promise<any> {
  // return new Promise((resolve,reject)=>{
  //   this.httpService.postDetailsForDeviceKeyCloak(url, data).then(res=>{
  //     resolve(res);
  //   }).catch(err=>{
  //     reject(err)
  //   })
  // })
  // }

  checkLoginStatus() {
    let currentDateInMillis = new Date().getTime();
    let expiryDateInMillis = Number(localStorage.getItem("userActiveTime"));
    let a = Math.abs((currentDateInMillis - expiryDateInMillis) / 1000);
    console.log("##a", a + " secs left")
    if (expiryDateInMillis) {
      let rememberMe = localStorage.getItem('rememberMe') || "false";
      if (this.currentPlatform == "browser" && rememberMe != "true" && Math.abs((currentDateInMillis - expiryDateInMillis) / 1000) > (20 * 60)) {
        this.confirmationAlertOkOnly(this.translateText("UI.COMMON.ALERT_TEXT"), this.translateText("UI.COMMON.PALNTECT.SESSION_ERROR"), this.translateText("UI.COMMON.OK_TEXT")).then(() => {
          this.logout();
        })
      }
    }
    console.log("Log");
  }
  httpGetApi(url): Observable<any> {
    this.checkLoginStatus();
    return this.httpService.httpGetApi(url);
  }
  httpGetApiForDataExport(url): Observable<any> {
    return this.httpService.httpGetApiDataExport2(url);
  }
  httpGetApiForDataExportMob(url, path?): Observable<any> {
    return this.httpService.httpGetApiDataExport2Mob(url, path);
  }
  httpGetApiWithoutHeader(url): Observable<any> {
    return this.httpService.httpGetApiWithoutHeader(url);
  }

  httpPostApiLogin(url, postData): Observable<any> {
    return this.httpService.httpPostApiLogin(url, postData);
  }

  httpPostApiLogout(url, postData): Observable<any> {
    return this.httpService.httpPostApiLogout(url, postData);
  }

  httpPostApi(url, postData): Observable<any> {
    return this.httpService.httpPostApi(url, postData);
  }

  httpPutApi(url, postData?, preLogin?): Observable<any> {
    return this.httpService.httpPutApi(url, postData, preLogin);
  }

  httpPutApiNameChange(url, postData): Observable<any> {
    return this.httpService.httpPutApiNameChange(url, postData);
  }

  httpDeleteApi(url): Observable<any> {
    return this.httpService.httpDeleteApi(url);
  }
  httpDeleteApiWithToken(url): Observable<any> {
    return this.httpService.httpDeleteApiToken(url);
  }
  httpPostApiWithToken(url, postData) {
    return this.httpService.httpPostApiWithToken(url, postData);
  }

  httpPostForSharing(url, postData) {
    return this.httpService.httpPostForSharing(url, postData);
  }
  /* getAccessToken() {
    return this.apiService.getAccessToken(this.isDevice);
  } */

  setExpiryDateInMillis(data) {
    let currentDateInMillis = new Date().getTime();
    currentDateInMillis += parseInt(data.expires_in) * 1000;
    localStorage.setItem("expiryDateInMillis", currentDateInMillis + "");
  }

  removeEmpty(obj) {
    Object.keys(obj).forEach((key) => {
      // tslint:disable-next-line: no-unused-expression
      (obj[key] &&
        typeof obj[key] === "object" &&
        this.removeEmpty(obj[key])) ||
        ((obj[key] === undefined || obj[key] === null || obj[key] === "") &&
          delete obj[key]);
    });
    return obj;
  }

  removeEmptyArray(obj) {
    Object.keys(obj).forEach((key) => {
      // tslint:disable-next-line: no-unused-expression
      obj[key] &&
        typeof obj[key] === "object" &&
        Array.isArray(obj[key]) &&
        obj[key].length === 0 &&
        delete obj[key];
    });
    return obj;
  }

  /************************** Key chain Service ***************************/
  async checkTokenValidity(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // if (!this.refreshTokenExpired) {
      try {
        let currentDateInMillis = new Date().getTime();
        let expiryDateInMillis = Number(
          localStorage.getItem("expiryDateInMillis")
        );
        if (currentDateInMillis <= (expiryDateInMillis - this.gapTimeToRefreshToken)) {
          console.log("Token valid");
          this.refreshTokenExpired = false;
          resolve("valid");
        } else { // if (currentDateInMillis > (expiryDateInMillis - this.gapTimeToRefreshToken))
          let params;
          this.refreshTokenExpired = true;
          let currentDatetime = new Date();
          console.log("Token expired. Generating new token", currentDatetime);
          let refreshToken;
          await this.keychainservice.retrieveSensitiveData("refreshToken", this.currentPlatform, true).then(res => {
            refreshToken = res;
            console.log("refresh Token", refreshToken);
            let login_url = "";
            login_url = this.appConfig.loginUrl;
            if (!refreshToken) reject("Invalid");
            if (this.currentPlatform == "browser") {
              params = {
                //client_secret: "ce44a1ae-8a13-476c-960c-b7b464538a7f",
                client_id: "mob-client",
                refresh_token: refreshToken,
                grant_type: "refresh_token"
              }
            } else if (window.hasOwnProperty("cordova")) {
              params = {
                //client_secret: "ce44a1ae-8a13-476c-960c-b7b464538a7f",
                client_id: "mob-client",
                refresh_token: refreshToken,
                grant_type: "refresh_token"
              }
            }
            console.log("Lgin Url", login_url, params);
            // this.presentLoading("");
            this.httpPostApiLogin(login_url, params).pipe(
              retry(2)
            ).subscribe(async accessToken => {
              // this.dismissLoading("");
              this.setExpiryDateInMillis(accessToken);
              console.log("New AccessToken", accessToken.access_token);
              await this.storeSensitiveData('accessToken', accessToken.access_token, true);
              await this.storeSensitiveData("refreshToken", accessToken.refresh_token, true);
              // keeping first refresh token for all the transaction, so not storing updated refresh token.

              // this.storeSensitiveData('refreshToken', accessToken.refresh_token);

              this.refreshTokenExpired = false;
              resolve("valid");
            }, err => {
              // this.dismissLoading("");
              this.refreshTokenExpired = false;
              console.log("error in refresh token", JSON.stringify(err));
              reject("Invalid");
              this.logout();
            })
          }).catch(err => {
            // this.dismissLoading("");
            console.log("##error", err);
          });
        }
      } catch (error) {
        // this.dismissLoading("");
      }
      // } else {
      //   resolve("valid");
      // }
    });
  }



  storeSensitiveData(keyname, data, callDelay?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.keychainservice
        .storeSensitiveData(keyname, data, callDelay)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          console.log("error in storing sensitive data", JSON.stringify(err));
        });
    });
  }

  retrieveSensitiveData(keyname): Promise<any> {
    return new Promise((resolve, reject) => {
      this.keychainservice
        .retrieveSensitiveData(keyname)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject();
          console.log(
            "error in getting token data from keychain and secure storage"
          );
        });
    });
  }

  clearSensitiveInfo(keyname): Promise<any> {
    return new Promise((resolve, reject) => {
      this.keychainservice.clearSensitiveInfo(keyname).then(res => {
        resolve(res);
      }).catch(err => {
        reject();
      })
    })
  }

  /************** Network Service  ***************/

  isNetworkOnline(): boolean {
    return this.networkService.isOnline();
  }

  showTimeOutLoading(duration?: any) {
    let message = "しばらくお待ちください" //this.translateText("UI.COMMON.PALNTECT.PLEASE_WAIT");
    this.loadingController.create({
      message: message,
      spinner: 'crescent',
      mode: 'ios',
      duration: duration,
    }).then((res) => {
      res.present();
      res.onDidDismiss().then((dis) => {
        console.log('Loading dismissed! after', duration, "milisec", dis);
      });
    });
  }

  async logout() {
    console.log("LOGOUT INVOKED...");
    try {
      let params;
      // if (this.currentPlatform == "browser") {
      //   params = new HttpParams({
      //     fromObject: {
      //       grant_type: "logout",
      //       refresh_token: localStorage.getItem('refreshToken'),
      //       session_state:"46511c61-917b-4d8e-8b8a-04775c12a267",
      //       client_secret: "ce44a1ae-8a13-476c-960c-b7b464538a7f",
      //       client_id: "fuji",
      //     },
      //   });
      // } else if (this.currentPlatform == "ios" || this.currentPlatform == "android") {
      let refreshToken;
      let keyclockId = localStorage.getItem("keyclockId");
      let session_state = localStorage.getItem("session_state");
      clearInterval(this.labelInterval);
      await this.clearDeviceToken();
      this.presentLoading("logout");
      let this_ = this;
      await this.keychainservice.retrieveSensitiveData("refreshToken", this.currentPlatform, true).then(res => {
        refreshToken = res;
        console.log("refresh Token", refreshToken);
        if (this.currentPlatform == "browser") {
          params = {
            session_state: session_state,
            //client_secret: "ce44a1ae-8a13-476c-960c-b7b464538a7f",
            client_id: "mob-client",
            grant_type: "logout",
            refresh_token: refreshToken,
            keycloak_user_id: keyclockId
          };
        } else if (this.currentPlatform == "ios" || this.currentPlatform == "android") {
          params = {
            session_state: session_state,
            //client_secret: "ce44a1ae-8a13-476c-960c-b7b464538a7f",
            client_id: "mob-client",
            grant_type: "logout",
            refresh_token: refreshToken,
            keycloak_user_id: keyclockId
          };
        }

      }).catch((err) => {
        this.dismissLoading("logout");
        return;
        console.log("Error in retrieveSensitiveData", JSON.stringify(err))
      })
      // }
      let logout_url = "";
      if (window.hasOwnProperty("cordova")) {
        logout_url = this.appConfig.logout_url;
      } else {
        logout_url =
          this.appConfig.browserLoginUrl + this.appConfig.logout_url;
      }
      console.log("logout_url", logout_url);
      console.log("params", params);
      this.connectedUsers = [];
      setTimeout(() => {
        this.httpPostApiLogout(logout_url, params).subscribe(
          (logout) => {
            this.navigateToLogin();
          },
          (err) => {
            this.navigateToLogin();
          }
        );
      }, 3000);
      // this.dismissLoading("logout");
    }
    catch (err) {
      // this.dismissLoading("logout");
    }
  }

  navigateToLogin() {
    this.dismissLoading("logout");
    this.analyticsService.logEvent(AnalyticsEvents.LOGOUT, {platform: this.currentPlatform});
    localStorage.setItem("expiryDateInMillis", "null");
    this.clearSensitiveInfo("refreshToken");
    this.alertCount = 0;
    this.clearSensitiveInfo("accessToken").then((res) => {
      localStorage.setItem("username", "");
      localStorage.setItem("rootusername", "");
      localStorage.setItem("region", "");
      localStorage.setItem("expires", "");
      localStorage.setItem("keyclockId", "");
      localStorage.setItem("fujiUserId", "");
      localStorage.setItem("connectedGH", "");
      localStorage.setItem("comparisonType", "");
      localStorage.setItem("session_state", "");
      localStorage.setItem("compareDate", "");
      localStorage.setItem("isUserLoggedIn", "");
      localStorage.setItem("selectedGH", "");
      localStorage.setItem("userActiveTime", '');
      localStorage.setItem('rememberMe', "");
      localStorage.setItem("userName", "");
      localStorage.removeItem("connection");
      localStorage.removeItem("isConnectedUser");
      localStorage.removeItem('greenhouse')
      this.checkUpdateToken(null);
      this.alertService.isAlertShown = false;
      // this.eventService.publish('logged-out', {});
      this.router.navigate(['/login'])
    });
  }
  public getMIMEtype(extn) {
    let ext = extn.toLowerCase();
    let MIMETypes = {
      'txt': 'text/plain',
      'csv': 'text/csv',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc': 'application/msword',
      'pdf': 'application/pdf',
      'jpg': 'image/jpeg',
      'bmp': 'image/bmp',
      'gif': 'image/gif',
      'png': 'image/png',
      'jpeg': 'image/jpeg',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'rtf': 'application/rtf',
      'ppt': 'application/vnd.ms-powerpoint',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    };
    return MIMETypes[ext];
  }


  async showToast(message: string, duration: number = 2000) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: duration
    });
    return toast.present();

  }

  dateConvertRegionbase(date?: any) {
    if (!date) {
      date = new Date();
    }

    let d = 'Asia/Seoul';

    if (localStorage.getItem("region") === "JP") {
      d = 'Asia/Tokyo';
    }

    return momenttz(date).tz(d);
  }

  milisecondToStringLocale(milisecond, formatString, locale?: any) {
    let date = moment(milisecond);
    date.locale(locale);
    return date.format(formatString);
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  roundTo(num, places: number) {
    if (num && this.isNumeric(num)) {
      const factor = 10 ** places;
      return Math.round(num * factor) / factor;
    } else {
      return num;
    }
  };

  has(obj, key) {
    const parts = key.split(".");
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if ((obj !== null) && (typeof (obj) === 'object') && (part in obj)) {
        obj = obj[part];
      } else {
        return false;
      }
    }
    return true;
  }
  getLatestSupportUpdates() {
    return new Promise((resolve, reject) => {
      var oldAnnFlag: boolean;
      var oldDocFlag: boolean;
      var supportLabelURL;
      var updateResponse
      if (this.currentPlatform == "browser") {
        supportLabelURL = "https://www.plantect.com/support-site/public/latestUpdate?cf=1646820106855&regionCode=JP";
      } else {
        supportLabelURL = "https://www.plantect.com/support-site/public/latestUpdate?cf=1646820106855&regionCode=JP"
      }
      this.httpClient.get(supportLabelURL).subscribe((response) => {
        updateResponse = response;
        /*updateResponse = {
          "announcementLatestUpdate": "2022-06-16T09:21:20.466+0000",
          "documentLatestUpdate": "2022-05-19T03:48:14.787+0000",
          "latestInfoDays": 7,
          "latestInfoHours": 48,
          "supportSiteUrl": "https://plantect-qa.apps.de1.bosch-iot-cloud.com:443/support-site"
        };*/
        var currentDate = new Date().getTime();
        var announcementLatestUpdate = new Date(updateResponse.announcementLatestUpdate).getTime();
        var documentLatestUpdate = new Date(updateResponse.documentLatestUpdate).getTime();
        var localLatestSupportClick = new Date(localStorage.getItem("localLatestSupportClick")).getTime();
        var localLatestSupportClickDate = new Date(localLatestSupportClick).getTime();
        var supportSiteNewIcon = false;

        var documentDateDiff = Math.abs(currentDate - documentLatestUpdate);
        var documentDateDiffDays = Math.ceil(documentDateDiff / (1000 * 60 * 60 * 24));

        var annDateDiff = Math.abs(currentDate - announcementLatestUpdate);
        const annDateDiffDays = Math.ceil(annDateDiff / (1000 * 60 * 60 * 24));

        if (annDateDiffDays > 7) {
          oldAnnFlag = true;
        } else {
          oldAnnFlag = false;
        }
        if (documentDateDiffDays > 7) {
          oldDocFlag = true;
        } else {
          oldDocFlag = false;
        }
        if (oldAnnFlag && oldDocFlag) {
          supportSiteNewIcon = false;
        } else if (oldAnnFlag == false || oldDocFlag == false) {
          if ((localLatestSupportClickDate < announcementLatestUpdate) || (localLatestSupportClickDate < documentLatestUpdate)) {
            supportSiteNewIcon = true;
          } else {
            supportSiteNewIcon = false;
          }
        }
        resolve({ "showSupportIcon": supportSiteNewIcon })
      }, (err) => {
        console.log("errr", err);
        reject(err);
      });
    });
  }

  isMobileApp() {
    if (this.platform.is('android') || this.platform.is("ios")) {
      return true;
    } else {
      return false;
    }
  }
  clearDeviceToken() {
    console.log("clearDeviceToken Invokedd.....");
    if (this.isNetworkOnline() && this.isMobileApp()) {
      let currentDatetime = new Date();
      this.setAuthorization().then((res) => {
        var deleteDeviceTokenURL = "";
        if (window.hasOwnProperty("cordova")) {
          deleteDeviceTokenURL =
            this.appConfig.browserLoginUrl +
            this.appConfig.baseUrl +
            this.appConfig.delete_deviceToken_url + this.firebaseToken;
        }
        console.log("deleteDeviceTokenURL", deleteDeviceTokenURL);
        this.httpDeleteApiWithToken(deleteDeviceTokenURL).subscribe(
          (response) => {
            console.log("Device Token response", response);
            localStorage.setItem("FirebaseRegistered", "false");
          },
          (error) => {
            console.log("Device Token error", JSON.stringify(error));
            localStorage.setItem("FirebaseRegistered", "false");
          });
      }).catch(err => {
        console.log("Error while setting authorization", err);
      });
    }
  }
  async navigateToSupport() {
    const supportURL:any =  await this.getSupportURL()
    if (window.hasOwnProperty("cordova")) {
      this.iab.create(supportURL, "_system");
    } else {
      window.open(supportURL);
    }
  }
  getSupportURL(regionCode?){
    return new Promise((resolve, reject) => {
      if (this.isNetworkOnline()) {
        console.log("token valid");
        var support_url;
        var region = localStorage.getItem("region");
        var fujiUserId = localStorage.getItem("fujiUserId");
        var userId = fujiUserId + "_" + region;
        var encodeId;
        encodeId = btoa(userId);
        if(!fujiUserId){
          if(regionCode == "Japan"){
            encodeId = "JP"
          }else if(regionCode == "Korea"){
            encodeId = "KR"
          }
        }
        if (this.showIcon) {
          this.showIcon = false;
        }
        var selectedLanguage = localStorage.getItem("setLanguage");
        var lang = "ja";
        if(selectedLanguage == "en"){
          lang = "eg"
        }else if(selectedLanguage == "ko"){
          lang = "ko"
        }
            if (this.currentPlatform == "browser") {
              support_url =
                this.appConfig.browserLoginUrl +
                this.appConfig.baseUrl +
                this.appConfig.support_url+"?userId="+encodeId+"&lan="+lang;
            } else {
              support_url =
                this.appConfig.baseUrl +
                this.appConfig.support_url+"?userId="+encodeId+"&lan="+lang;
            }
            this.httpGetApi(support_url).subscribe(res=>{
              if (this.platform.is("android") || this.platform.is("ios")) {
                res = JSON.parse(res.data);
              }
              resolve(res.url);
            },err => {
              console.log("###err",JSON.stringify(err));
            })
          }
    });
  }
  getInvitees() {
    return new Promise((resolve, reject) => {
      if (this.isNetworkOnline()) {
        this.checkTokenValidity().then((res) => {
          this.setAuthorization().then((res) => {
            var acceptInviteURL = ''
            if (window.hasOwnProperty("cordova")) {
              acceptInviteURL =
                this.appConfig.baseUrl +
                this.appConfig.send_invite_url;
            } else {
              acceptInviteURL =
                this.appConfig.browserLoginUrl +
                this.appConfig.baseUrl +
                this.appConfig.send_invite_url;
            }
            this.httpGetApi(acceptInviteURL).subscribe(async res => {
              console.log("##INvited  me", JSON.stringify(res));
              if (this.platform.is("android") || this.platform.is("ios")) {
                res = await JSON.parse(res.data);
              }
              resolve(res);
            }, err => {
              console.log("##INvited  err", JSON.stringify(err));
              reject(err)
            })
          })
        })
      }
    });
  }
  loadConnectedUsers() {
    return new Promise((resolve, reject) => {
      if (this.isNetworkOnline()) {
        this.checkTokenValidity().then(
          (res) => {
            console.log("token valid");
            var connected_users_url;
            this.setAuthorization().then(
              (re) => {
                //Local Json path for green house cards
                // var localJsonforGreenHouses = this.appConfig.localJsonPath+this.appConfig.plantectCardLocalJson;
                if (this.currentPlatform == "browser") {
                  connected_users_url =
                    this.appConfig.browserLoginUrl +
                    this.appConfig.baseUrl +
                    this.appConfig.connected_users_url;
                } else {
                  connected_users_url =
                    this.appConfig.baseUrl +
                    this.appConfig.connected_users_url;
                }
                console.log("access key url", connected_users_url);

                this.httpGetApi(connected_users_url).subscribe(
                  (res) => {
                    console.log("##conncted USERS", res);
                    if (this.platform.is("android") || this.platform.is("ios")) {
                      this.connectedUsers = JSON.parse(res.data);
                    } else {
                      this.connectedUsers = res;
                    }
                    resolve(this.connectedUsers);
                  },
                  (err) => {

                  });
              }).catch(err => {
              })
          }).catch(err => {
          });
      };
    });
  }
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  getGreenHouseData() {
    return new Promise((resolve, reject) => {
      if (this.isNetworkOnline()) {
        this.checkTokenValidity().then((res) => {
          this.setAuthorization().then((res) => {
            var greenhouse_summary_api;
            if (this.currentPlatform == "browser") {
              greenhouse_summary_api =
                this.appConfig.browserLoginUrl +
                this.appConfig.greenhouse_summary_api +
                "?compact=true";
            } else {
              greenhouse_summary_api =
                this.appConfig.greenhouse_summary_api + "?compact=true";
            }
            this.httpGetApi(greenhouse_summary_api).subscribe(
              (res) => {
                try {
                  var convertedResponse;
                  if (this.currentPlatform == "browser") {
                    convertedResponse = res;
                  } else {
                    convertedResponse = JSON.parse(res.data);
                  }
                  resolve(convertedResponse);
                } catch (e) {
                }
              },
              (err) => {
                reject(err);
              }
            );
          });
        });
      }
    });
  }
  hasKey(obj, key) {  
    if (typeof obj !== 'object' || obj === null) {  
      return false;  
    }  
    if (key in obj) {  
      return true;  
    }  
    for (var k in obj) {  
      if (this.hasKey(obj[k], key)) {  
        return true;  
      }  
    }  
    return false;  
  }  
}
