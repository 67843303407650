import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert-service';
import { Observable } from 'rxjs';
import { ToastService } from './toast-service';

@Injectable()
export class TranslationService {

    onDevice: boolean;

    constructor(private platform: Platform, public translate: TranslateService, private alertService: AlertService, private toastService: ToastService) {
        this.onDevice = this.platform.is('cordova');
    }

    initTransalation() {
        if (localStorage.getItem('setLanguage') == undefined || localStorage.getItem('setLanguage') == null) {
            this.translate.setDefaultLang('en');
            this.changeCurrentBrowserLang('en');
            localStorage.setItem('setLanguage', 'en');
        }
        else if(localStorage.getItem('setLanguage') === 'en')
        {
            this.translate.setDefaultLang('en');
            this.changeCurrentBrowserLang('en');
            localStorage.setItem("setLanguage",'en');
        } else {
            if (localStorage.getItem("region") == undefined || localStorage.getItem("region") == null || localStorage.getItem("region") == "")
            {
                
                this.translate.setDefaultLang(localStorage.getItem('setLanguage').toLowerCase());
                this.changeCurrentBrowserLang(localStorage.getItem('setLanguage').toLowerCase());
            }
            else{

                if(localStorage.getItem("region") === 'JP')
                {
                    this.translate.setDefaultLang('ja');
                    this.changeCurrentBrowserLang('ja');
                    localStorage.setItem("setLanguage",'ja');
                }
                else if(localStorage.getItem("region") === 'KR')
                {
                    this.translate.setDefaultLang('ko');
                    this.changeCurrentBrowserLang('ko');
                    localStorage.setItem("setLanguage",'ko');
                }

            }
            
        }
    }

    changeCurrentBrowserLang(lang) {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }
    alertTranslator(header, msg, btnText, params?, subHeader?, cssClass?) {
        let title, message, subTitle;
        const buttonText = btnText;
        this.translate.get(header).subscribe(value => {
            title = value;
        });
        if (subHeader) {
            this.translate.get(subHeader).subscribe(value => {
                subTitle = value;
            });
        }
        if (msg.split('#').length > 1) {
            this.translate.get(msg.split('#')[0], params).subscribe(value => {
                message = value + ' ' + msg.split('#')[1];
            });
        } else {
            this.translate.get(msg, params).subscribe(value => {
                message = value;
            });
        }
        for (let i = 0; i < buttonText.length; i++) {
            this.translate.get(buttonText[i].text).subscribe(value => {
                buttonText[i].text = value;
            });
        }
        this.alertService.showAlert(title, message, 'ios', buttonText, subTitle, cssClass);
    }
    /* CheckboxAlertTranslator(msg, type, value, flag, btn1, btn2, className, onConfirm) {
        let message;
        let btnText1;
        let btnText2;
        this.translate.get(msg).subscribe(result => {
            message = result;
        });
        this.translate.get(btn1).subscribe(result => {
            btnText1 = result;
        });
        this.translate.get(btn2).subscribe(result => {
            btnText2 = result;
            this.alertService.showCheckboxAlert(message, type, value, flag, btnText1, btnText2, className, onConfirm);
        });
    } */
    ToastMessageTranslate(message, duration, position, className) {
        let msg;
        this.translate.get(message).subscribe(value => {
            msg = value;
            this.toastService.showToast(msg, duration, position, '', '', className);
        });

    }

    Dotranslate(message, params?): Observable<any> {
        return this.translate.get(message, params);
    }

    translateText(text: string): any
    {
     return this.translate.instant(text);
     
    }

    /* radioCheckListAlertTranslator(title, listObjects, type, btn1, btn2, className, onConfirm) {
        let translatedTitle;
        const translatedList = [];
        let translatedBtn1;
        let translatedBtn2;
        this.translate.get(title).subscribe(result => {
            translatedTitle = result;
        });
        if (btn1 != null && btn1 != undefined) {
            this.translate.get(btn1).subscribe(result => {
                translatedBtn1 = result;
            });
        }

        this.translate.get(btn2).subscribe(result => {
            translatedBtn2 = result;
        });
        for (let i = 0; i < listObjects.length; i++) {
            const counter = i;
            this.translate.get(listObjects[counter].label).subscribe(result => {
                listObjects[counter].label = result;
                translatedList.push(listObjects[counter]);
                if (counter >= listObjects.length - 1) {
                    this.alertService.showRadioOrCheckListAlert(translatedTitle, translatedList, type, translatedBtn1, translatedBtn2, className, onConfirm);
                }
            });
        }
    } */

    /* loaderTranslatorWithoutDuration(message) {
        this.translate.get(message).subscribe(value => {
            const messageToShow = value;
            this.loaderService.presentLoadingWithoutDuration(messageToShow);
        });
    } */

    htmlAlertTransaltor(titleTxt, msg, html, btn, className) {
        let title;
        let message;
        let buttonText;
        title = ' ';
        this.translate.get(titleTxt).subscribe(value => {
            title = value;
        });
        if (msg.split('#').length > 1) {
            this.translate.get(msg.split('#')[0]).subscribe(value => {
                message = value + ' ' + msg.split('#')[1];
            });
        } else {
            this.translate.get(msg).subscribe(value => {
                message = value;
            });
        }

        this.translate.get(btn).subscribe(value => {
            buttonText = value;
            this.alertService.showAlert(title, message + html, buttonText, className);
        });
    }
}